<template>    
    <ContratoForm v-if="abrirFormulario()" :id="id" :clonar="isClonar" />
    <ContratoList v-if="!abrirFormulario()" />
</template>

<script>
import ContratoForm from '../../components/cadastro/contrato/ContratoForm.vue';
import ContratoList from '../../components/cadastro/contrato/ContratoList.vue';

export default {
    name: 'ContratoPage',
    components: { ContratoForm, ContratoList },

    data() {
        return {
            isCadastro: false,
            isEditar: false,
            isClonar: false,
            id: null
        }
    },

    created() {
        this.validarRouter();
    },

    updated() {
        this.validarRouter();
    },

    methods: {
        iniciarParametros(id) {
            this.isCadastro = false;
            this.isEditar = false;
            this.isClonar = false;
            this.id = null;
        },

        abrirFormulario() {
            return this.isCadastro || this.isEditar || this.isClonar;
        },

        validarRouter() {
            this.iniciarParametros();
            const routerName = this.$route.name
            
            if(routerName === 'contrato-cadastro') {
                this.isCadastro = true;
            }
            else if(routerName === 'contrato-editar') {
                const id = parseInt(this.$route.params.id);
                this.id = id;
                this.isEditar = true;
            }
            else if(routerName === 'contrato-clonar') {
                const id = parseInt(this.$route.params.id);
                this.id = id;
                this.isClonar = true;
            }
            else {
                this.isCadastro = false;
                this.isEditar = false;
                this.id = null;
            }
        }
    }
}
</script>
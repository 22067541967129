import axios from "axios";
import ApiUrl from '../config/ApiUrl';

class CotacaoDolarService {
    buscarPorData(data) {
        return axios.get(`${ ApiUrl.vg }api/genesis-vg/v1/cotacao/buscar-por-data/${ data }`);
    }

}

export default new CotacaoDolarService();
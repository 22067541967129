<template>
    <div class="grid p-fluid formgrid">
        <div class="col-12 sm:col-8 md:col-8 lg:col-8 xl:col-8">
            <div class="field">
                <label for="endereco">
                    <strong>Endereço</strong>
                </label>
                <InputText v-model="dados.corretora.endereco" id="endereco" :disabled="true" />
            </div>
        </div>

        <div class="col-12 sm:col-4 md:col-4 lg:col-4 xl:col-4">
            <div class="field">
                <label for="bairro">
                    <strong>Bairro</strong>
                </label>
                <InputText v-model="dados.corretora.bairro" id="bairro" :disabled="true" />
            </div>
        </div>

        <div class="col-12 sm:col-6 md:col-6 lg:col-6 xl:col-6">
            <div class="field">
                <label for="municipio">
                    <strong>Município</strong>
                </label>
                <InputText v-model="dados.corretora.cidade" id="municipio" :disabled="true" />
            </div>
        </div>

        <div class="col-12 sm:col-2 md:col-2 lg:col-2 xl:col-2">
            <div class="field">
                <label for="cep">
                    <strong>CEP</strong>
                </label>
                <InputText v-model="dados.corretora.cep" id="cep" :disabled="true" />
            </div>
        </div>

        <div class="col-12 sm:col-4 md:col-4 lg:col-4 xl:col-4">
            <div class="field">
                <label for="email">
                    <strong>E-mail</strong>
                </label>
                <InputText v-model="dados.corretora.email" id="cep" :disabled="true" />
            </div>
        </div>

        <div class="col-12 sm:col-4 md:col-4 lg:col-4 xl:col-4">
            <div class="field">
                <label for="contato">
                    <strong>Contato</strong>
                </label>
                <InputText v-model="dados.corretora.contato" id="contato" :disabled="true" />
            </div>
        </div>

        <div class="col-12 sm:col-2 md:col-2 lg:col-2 xl:col-2">
            <div class="field">
                <label for="telefone">
                    <strong>Telefone</strong>
                </label>
                <InputText v-model="dados.corretora.telefone" id="telefone" :disabled="true" />
            </div>
        </div>

        <div class="col-12 sm:col-2 md:col-2 lg:col-2 xl:col-2">
            <div class="field">
                <label for="celular">
                    <strong>Celular</strong>
                </label>
                <InputText v-model="dados.corretora.celular" id="celular" :disabled="true" />
            </div>
        </div>

        <div class="col-12 sm:col-4 md:col-4 lg:col-4 xl:col-4">
            <div class="field">
                <label for="comissao">
                    <strong>Comissão (%)</strong>
                </label>
                <InputText v-model="dados.comissao" id="comissao" :disabled="true" />
            </div>
        </div>

        <div class="col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12">
            <div class="field">
                <label for="outros_termos">
                    <strong>Outros termos</strong>
                </label>
                <Editor v-model="dados.outrosTermos" editorStyle="height: 320px" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        dados: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
        }
    },
    created() {
        if(!this.dados.corretora) {
            this.dados.corretora = {};
        }
    },
    methods: {
    }
}
</script>
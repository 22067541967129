<template>
    <div class="card">
        <div class="flex align-items-center">
            <img src="../../../assets/img/contrato.png" style="width: 2.3rem; margin-right: 0.5rem;"/> 
           <h3 style="margin:0px 5px;">Contratos de Compra e Venda</h3>
        </div>

        <hr />

        <DataTable 
            responsiveLayout="scroll"
            scrollDirection="both"
            :scrollable="true"
            :value="data"
            v-model:selection="selecionado"
            selectionMode="single"
            showGridlines
            @sort="sort($event)">

            <template #header>
                <div class="grid p-fluid formgrid align-items-center">

                  <div class="col-12 lg:col-1">
                    <div class="field">
                      <label for="nr-ci">
                        <strong>Contrato Interno</strong>
                      </label>
                      <InputNumber id="nr-ci" class="w-full" v-model="filter.idContrato" placeholder="Nº contrato"/>
                    </div>
                  </div>

                    <div class="col-12 lg:col-4" >
                      <div class="field">
                        <label>
                          <strong>Cliente</strong>
                        </label>
                        <ProdutorFiltro
                            tituloModal="Buscar Comprador"
                            :disabled="false"
                            :campoObrigatorio="true"
                            :selecionado="filter.compradorSelecionado"
                            :paginacao="compradorConsulta"
                            @pesquisar="pesquisarComprador"
                            @selecionado="selecionarComprador"
                        />
                      </div>
                    </div>

                    <div class="field mr-2">
                        <label>
                            <strong>Período Contrato</strong>
                        </label>

                        <div class="flex align-items-center">
                            <div class="overflow-hidden" style="width:6rem;">
                                <Calendar
                                    class="w-full"
                                    v-model="dataContratoInicio"
                                    autocomplete="off"
                                    dateFormat="dd/mm/y"
                                />
                            </div>

                            <strong class="ml-2 mr-2">à</strong>
                            
                            <div class="overflow-hidden" style="width:6rem;">
                                <div class="p-inputgroup">
                                    <Calendar
                                        class="w-full"
                                        v-model="dataContratoFim"
                                        autocomplete="off"
                                        dateFormat="dd/mm/y"
                                        :minDate="dataContratoInicio"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mr-2 mt-2">
                        <div class="p-inputgroup">
                            <Button
                                label="Filtrar"
                                icon="pi pi-filter"
                                class="p-button-outlined p-button-info"
                                style="margin-left: 1px; margin-top: 0.5rem"
                                @click="carregarDados()"
                            ></Button>

                            <Button 
                                title="Limpar Filtro"
                                icon="pi pi-filter-slash"
                                class="p-button-outlined p-button-info"
                                style="margin-left: 1px; margin-top: 0.5rem"
                                @click="limparFiltro()"
                            ></Button>
                          <Button
                              v-if="filter.situacao == 'ATIVO'"
                              type="button"
                              icon="pi pi-ban"
                              label="Listar inativos"
                              class="p-button-outlined p-button-danger mr-2 mt-2"
                              @click="filter.situacao = 'INATIVO'; carregarDados()"
                          ></Button>

                          <Button
                              v-if="filter.situacao == 'INATIVO'"
                              type="button"
                              icon="pi pi-check-circle"
                              label="Listar ativos"
                              class="p-button-outlined mr-2 mt-2"
                              @click="filter.situacao = 'ATIVO'; carregarDados()"
                          ></Button>
                          <Button
                              icon="pi pi-filter"
                              label="Filtro Avançado"
                              class="p-button-outlined"
                              style="min-width:150px; margin-top:0.5rem"
                              @click="contratoFiltroAvancadoDialog = true"
                          ></Button>

                          <Button
                              label="Cadastrar"
                              icon="pi pi-plus"
                              class="p-button-info mr-2 mt-2"
                              style="left:1rem"
                              @click="cadastrar()"
                          ></Button>

                        </div>
                    </div>

                </div>
            </template>

            <template #empty>
                Nenhum registro encontrado.
            </template>

            <template #loading>
                Carregando... Por favor, aguarde.
            </template>

            <Column field="contratoInternoId" header="Nro. CI" 
                style="width:5rem; font-weight: bold; background-color: rgb(229, 241, 247)" frozen/>

            <Column header="Vendedor" style="width:5rem;">
                <template #body="{ data }">
                    <span :title="data.vendedor.nome">{{ data.vendedor.codigo}}</span>
                </template>
            </Column>

            <Column header="Comprador" style="width:35rem;">
                <template #body="{ data }">
                    {{ data.comprador.codigo + " - " + data.comprador.nome }}
                </template>
            </Column>
            <Column header="Corretora" style="width:35rem;" sortable>
                <template #body="{ data }">
                    {{ data.corretoraDto.codigoCliente + " - " + data.corretoraDto.nome }}
                </template>
            </Column>

            <Column field="contratoCorretora" style="width:10rem;" header="Contrato Corretora" />
            <Column header="Data Contrato Corretora" style="width:12rem;">
                <template #body="{ data }">
                    {{ formatDateCustom(data.dataControtato, 'DD/MM/YYYY') }}
                </template>
            </Column>

            <Column field="contratoJuridico" header="Contrato Jurídico" style="width:10rem;"/>

            <Column field="produtoDescItem" header="Produto" style="width:7rem;"/>
            <Column field="grupoProdutoCodigo" header="Grupo Produto" style="width:10rem;"/>

            <Column header="Quantidade" alignFrozen="right" frozen
                style="width:10rem; font-weight: bold; background-color: rgb(229, 241, 247); text-align: right;" >
                <template #body="{ data }">
                    {{ formatDecimal(data.quantidadeEmKg, 0, 0) }} kg
                </template>
            </Column>
            <Column header="Ações" style="width:12rem;" alignFrozen="right" frozen>
                <template #body="{ data }">
                    <div class="flex-none">
                        <Button 
                            v-if="data.ativo"
                            class="p-button-rounded p-button-gray flex-none mr-2" 
                            title="Clonar"  
                            icon="pi pi-copy" 
                            @click="clonar(data.id)"
                        ></Button>

                        <Button 
                            title="Visualizar histórico"
                            icon="pi pi-list"
                            class="p-button-rounded p-button-warning flex-none mr-2"
                            @click="visualizarHistorico(data)"
                        ></Button>
                        <Button
                            title="Editar"
                            icon="pi pi-pencil"
                            class="p-button-rounded p-button-info flex-none mr-2"
                            v-if="data.ativo"
                            @click="editar(data.id)"
                        ></Button>

                        <Button 
                            v-if="data.ativo"
                            title="Inativar"
                            class="p-button-rounded flex-none p-button-danger"
                            icon="pi pi-ban"
                            @click="confirmarAtivarOuInativar(data)"
                        ></Button>

                        <Button 
                            v-if="!data.ativo"
                            title="Ativar"
                            class="p-button-rounded flex-none p-button-info"
                            icon="pi pi-check-circle"
                            @click="confirmarAtivarOuInativar(data)"
                        ></Button>
                    </div>
                </template>
            </Column>
        </DataTable>

        <Paginator
            v-model:first="primeiraLinha"
            :rows="qtdRegistroPorPagina"
            :total-records="totalRegistro"
            :rowsPerPageOptions="[5, 10, 20, 30]"
            @page="onPage" />

    </div>

    <Dialog v-model:visible="ativarOuInativarDialog" :style="{width: '300px'}" header="Alerta" :modal="true">
        <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
            <span>Tem certeza que deseja <strong>{{ selecionado.ativo ? 'inativar' : 'ativar' }}</strong> o contrato: <strong>{{ selecionado.id }}</strong>?</span>
        </div>
        <template #footer>
            <Button label="Não" icon="pi pi-times" class="p-button-text" @click="ativarOuInativarDialog = false" />
            <Button label="Sim" icon="pi pi-check" class="p-button-text p-button-danger" @click="ativarOuInativar()" />
        </template>
    </Dialog>

    <ContratoFiltroAvancado
        :visivel="contratoFiltroAvancadoDialog"
        :filter="filter"
        @filtrar="filtrar">
    </ContratoFiltroAvancado>

    <ModalHistoricoGenerico
        codigoPagina="CCV"
        :id="selecionado.id"
        :descricao="descricaoHistoricoDialog"
        :visivel="historicoDialog"
        @fechar="ocultarHistorico()"
    />
</template>

<script>
import Formatacao from '../../../utilities/Formatacao';
import StorageService from '../../../service/StorageService';
import ModalHistoricoGenerico from '../../ModalHistoricoGenerico.vue';
import ContratoCompraVendaService from '../../../service/ContratoCompraVendaService';
import ProdutorFiltro from "@/components/cadastro/ProdutorFiltro.vue";
import ProdutorService from "@/service/ProdutorService";
import ContratoFiltroAvancado from "@/components/zoom/ContratoFiltroAvancado.vue";

export default {
    components: {ContratoFiltroAvancado, ProdutorFiltro, ModalHistoricoGenerico },

    name: 'ContratoList',

    data() {
        return {
            pagina: 0,
            primeiraLinha: 0,
            totalRegistro: 0,
            qtdRegistroPorPagina: 20,  

            data: [],
            
            /* FILTRO INICIO */
            dataContratoFim: null,
            dataContratoInicio: null,

            corretoraSelecionada: {},

            campoInvalido: {},
            contratoConsulta: {},

            /* FILTRO FIM */

            filter: {},
            ordenar: 'id,asc',
            
            selecionado: {},
            historicoDialog: false,
            ativarOuInativarDialog: false,
            descricaoHistoricoDialog: null,
            contratoFiltroAvancadoDialog: false,
            compradorConsulta: {},
            
            storange: StorageService.getControlePagina("CONTRATO-COMPRA-VENDA"),
            storangeMessage: StorageService.getMensagemToast("CONTRATO-COMPRA-VENDA")    
        }
    },

    created() {
        //verifica se o filtro está vazio
        this.filter.situacao = "ATIVO";

        if(this.storange.default == undefined){
            this.filter = this.storange.filtros;
        }

        this.filter["compradorSelecionado"] = {codProdutor:""};
        this.pagina = this.storange.numero;
        this.primeiraLinha = this.storange.firstRow;
        this.qtdRegistroPorPagina = this.storange.qtdRegistro; 
        this.carregarDados();
        this.exibirMensagemToast();
    },

    methods: {
        
        exibirMensagemToast() {
            if (this.storangeMessage.active){
                this.$toast.add({
                    severity: this.storangeMessage.severity,
                    summary: this.storangeMessage.summary,
                    detail: this.storangeMessage.detail, 
                    life: 7500
                });
                StorageService.setMensagemToast("CONTRATO-COMPRA-VENDA", 'info', '', '', false);
            }
        },
        
        cadastrar() {
            this.$router.push(`/contrato/cadastro`);
        },

        editar(contratoId) {                
            this.$router.push(`/contrato/editar/${ contratoId }`);
        },

        clonar(contratoId) {
            this.$router.push(`/contrato/clonar/${ contratoId }`);
        },

        filtrar(filtro){
          this.contratoFiltroAvancadoDialog = false;
          if (!!filtro) {this.carregarDados(filtro);}
        },

        carregarDados(filtros) {
            this.filter["page"] = this.pagina;
            this.filter["size"] = this.qtdRegistroPorPagina;
            this.filter["sort"] = this.ordenar;
            this.filter["dataContratoFim"] = null;
            this.filter["dataContratoInicio"] = null;

            if(!!this.dataContratoInicio) {
                this.filter["dataContratoInicio"] = this.formatDateCustom(this.dataContratoInicio, "YYYY-MM-DD");
            }

            if(!!this.dataContratoFim) {
                this.filter["dataContratoFim"] = this.formatDateCustom(this.dataContratoFim, "YYYY-MM-DD");
            }

            if(!!filtros){
              this.filter["contratoJuridico"] = filtros.contratoJuridico;
              this.filter["contratoCorretora"] = filtros.contratoCorretora;
              this.filter["corretoraId"] = filtros.corretoraId;
              this.filter["periodoInicio"] = filtros.periodoInicio;
              this.filter["periodoFim"] = filtros.periodoFim;
              this.filter["produtoId"] = filtros.idProduto;
            }

            if (!!this.filter.compradorSelecionado.codProdutor){
              this.filter["codigoComprador"] = this.filter.compradorSelecionado.codProdutor;
            } else {
              this.filter["codigoComprador"] = undefined;
            }

            this.filter["contratoInternoId"] = this.filter.idContrato;

            ContratoCompraVendaService.buscarComPaginacao(this.filter)
                .then(({ data }) => {
                    if(data) {
                        this.data = data.content;
                        this.qtdRegistroPorPagina = data.pageable.pageSize;
                        this.totalRegistro = data.totalElements;
                    }
                })
                .catch(error => {
                    const toast = {
                        severity:'error',
                        summary: 'Falha ao buscar contratos',
                        detail:`Falha não mapeada.`, 
                        life: 7500
                    };

                    if(!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;
                        toast["detail"] = message;
                    }

                    this.$toast.add(toast);
                });
                
            StorageService.setControlePaginaFilter("CONTRATO-COMPRA-VENDA", this.pagina, this.primeiraLinha, this.qtdRegistroPorPagina, this.filter);               
        },

        pesquisarComprador(filtros) {
          ProdutorService.pesquisarProdutor(filtros)
              .then(({ data }) => {
                if(data) {
                  this.compradorConsulta = data;
                }
              })
              .catch(error => {
                this.compradorConsulta = {}

                const toast = {
                  severity: 'error',
                  summary: 'Falha ao carregar os compradores.',
                  detail: error,
                  life: 7500
                }

                if(!!error && !!error.response && !!error.response.data) {
                  const { message } = error.response.data;
                  toast["detail"] = message;
                  toast["severity"] = 'warn';
                }

                this.$toast.add(toast);
              });
        },

        selecionarComprador(codProdutor) {
          if(codProdutor == undefined || codProdutor == null || codProdutor.trim() == "") {
            this.compradorSelecionado = {};
            this.filter.compradorSelecionado = {};
            return;
          }

          ProdutorService.buscarPorCodProdutorInfoContrato(codProdutor)
              .then(({ data }) => {
                if(data) {
                  this.compradorSelecionado = data;
                  this.filter.compradorSelecionado = data;

                }
              })
              .catch(error => {
                this.compradorSelecionado = {};
                this.filter.compradorSelecionado = {};


                const toast = {
                  severity: 'error',
                  summary: `Falha ao buscar o comprador.`,
                  detail: error,
                  life: 7500
                }

                if(!!error && !!error.response && !!error.response.data) {
                  const { message } = error.response.data;
                  if(!!message) {
                    toast["detail"] = message.replace("produtor", "comprador");
                  }
                  toast["severity"] = 'warn';
                }

                this.$toast.add(toast);
              });
        },

        limparFiltro() {
            this.filter["corretoraId"] = undefined;
            this.filter["produtoId"] = undefined;
            this.filter["idProduto"] = undefined;
            this.filter["dataContratoFim"] = null;
            this.filter["dataContratoInicio"] = null;
            this.filter["periodoInicio"] = undefined;
            this.filter["periodoFim"] = undefined;
            this.filter["contratoInternoId"] = null;
            this.filter["idContrato"] = null;
            this.filter["idCorretora"] = undefined;
            this.filter["corretoraId"] = undefined;
            this.filter["compradorSelecionado"] = {codProdutor:""};
            this.filter["idProduto"] = undefined;
            this.filter["contratoJuridico"] = undefined;
            this.filter["contratoCorretora"] = undefined;

            this.corretoraSelecionada = {};
            this.contratoConsulta = {};
            this.tipoProdutoSelecionado = null;
            this.dataContratoFim = null;
            this.dataContratoInicio = null;

            this.carregarDados();
        },

        confirmarAtivarOuInativar(contrato) {
            this.selecionado = contrato;
            this.ativarOuInativarDialog = true;
        },

        ativarOuInativar() {
            this.ativarOuInativarDialog = false;
            if(this.selecionado.ativo) {
                ContratoCompraVendaService.inativar(this.selecionado.id)
                    .then(response => {
                        this.$toast.add({
                            severity:'success',
                            summary: 'Inativado',
                            detail:`O contrato de compra e venda foi inativado!`, 
                            life: 7500
                        });

                        this.carregarDados();                        
                        this.selecionado = {};
                    })
                    .catch(error => {
                        const toast = {
                            severity:'error',
                            summary: 'Falha ao inativar',
                            detail:`Falha não mapeada.`, 
                            life: 7500
                        };

                        if(!!error && !!error.response && !!error.response.data) {
                            const { message, exception } = error.response.data;
                            toast["detail"] = message + ": "+ exception;
                        }

                        this.$toast.add(toast);
                    });
            }
            else {
                ContratoCompraVendaService.ativar(this.selecionado.id)
                    .then(response => {
                        this.$toast.add({
                            severity:'success',
                            summary: 'Ativada',
                            detail:`O contrato de compra e venda foi ativado!`, 
                            life: 7500
                        });
                        
                        this.carregarDados();
                        this.selecionado = {};
                    })
                    .catch(error => {
                        const toast = {
                            severity:'error',
                            summary: 'Falha ao ativar',
                            detail:`Falha não mapeada.`, 
                            life: 7500
                        };

                        if(!!error && !!error.response && !!error.response.data) {
                            const { message } = error.response.data;
                            toast["detail"] = message;
                        }

                        this.$toast.add(toast);
                    });
            }
        },

        visualizarHistorico(contrato) {
            this.descricaoHistoricoDialog = "Contrato Interno: "+ contrato.contratoInternoId;
            this.historicoDialog = true;
            this.selecionado = contrato;
        },

        ocultarHistorico() {
            this.historicoDialog = false;
            this.selecionado = {};
        },

        formatDateCustom(date, format) {
            return Formatacao.formatDateCustom(date, format);
        },

        formatDecimal(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
            return Formatacao.formatDecimal(value, minimumFractionDigits, maximumFractionDigits);
        },

        onPage(event) {
            this.pagina = event.page + 1;
            this.primeiraLinha = event.rows * event.page;
            this.qtdRegistroPorPagina = event.rows; 
            this.carregarDados();
        },

        sort(event) {
            const sortField = event.sortField;
            const sortOrder = event.sortOrder == 1 ? 'asc' : 'desc';
            this.ordenar = `${ sortField },${sortOrder}`;
            this.carregarDados();
        }
    }
}
</script>

<style>
    .centralizar-titulo-tabela {
        text-align: center !important;
    }

    .centralizar-titulo-tabela .p-column-header-content {
        display: inline;
    }
</style>

<template>
    <Dialog v-model:visible="visivel" :style="{width: '600px', margin: '10%'}" :modal="true" @hide="fechar()">
        <template #header>
            <div class="flex align-items-center mt-2">
                <h4 style="margin:0;">Parcela</h4>
            </div> 
        </template>

        <Message v-if="mensagem.ativo" :severity="mensagem.gravidade" @close="mensagem.ativo = false">{{ mensagem.conteudo }}</Message>

        <div class="grid p-fluid formgrid">
            <div class="col-4 sm:col-4 md:col-4 lg:col-4 xl:col-4">
                <div class="field">
                    <label for="data">
                        <strong>Data<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <Calendar
                        class="w-full"
                        :class="{ 'p-invalid': isValidData }"
                        v-model="dados.data"
                        dateFormat="dd/mm/yy"
                        @focus="limparMensagem"
                    />
                </div>
            </div>

            <div class="col-4 sm:col-4 md:col-4 lg:col-4 xl:col-4">
                <div class="field">
                    <label for="percentual">
                        <strong>Percentual<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <InputNumber
                        class="w-full"
                        :class="{ 'p-invalid': isValidP }"
                        v-model="dados.percentual"
                        id="percentual"
                        locale="pt-BR"
                        :max="9999999"
                        :minFractionDigits="2"
                        :maxFractionDigits="2"
                    />
                </div>
            </div>

            <div class="col-4 sm:col-4 md:col-4 lg:col-4 xl:col-4">
                <div class="field">
                    <label for="valor_total">
                        <strong>Valor Total<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <InputNumber
                        class="w-full"
                        :class="{ 'p-invalid': isValidValorTotal }"
                        v-model="dados.valorTotal"
                        id="valor_total"
                        locale="pt-BR"
                        :max="9999999"
                        :minFractionDigits="2"
                        :maxFractionDigits="2"
                    />
                </div>
            </div>
        </div>    

        <template #footer>
            <div class="flex justify-content-center">
                <Button
                    icon="pi pi-save"
                    label="Salvar"
                    :disabled="doubleClick"
                    class="p-button-info ml-1"
                    @click="salvar()"
                ></Button>

                <Button
                    icon="pi pi-times"
                    label="Cancelar"
                    class="p-button-danger"
                    @click="fechar()"
                ></Button>
            </div>
        </template>
    </Dialog>
</template>

<script>
import UnidadeMedidaService from './service/UnidadeMedidaService';
export default {
    name: "ModalParcela",
    emits: ["fechar"],
    props: {
        visivel: {
            type: Boolean,
            required: true
        },
        dados: {
            type: Object,
            required: true
        },
        valorDolar: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            unidadeFiltro: [],
            unidades: UnidadeMedidaService.lista(),

            mensagem: { ativo: false, gravidade: 'info', conteudo: '' },

            isValidData: false,
            isValidPercentual: false,
            isValidValorTotal: false,
        }
    },
    methods: {
        isCamposObrigatoriosPreenchidos() {
            let isPreenchido = true;

            if(!this.dados.data) {
                this.isValidData = true;
                isPreenchido = false;
            }
            
            if(!this.dados.percentual) {
                this.isValidPercentual = true;
                isPreenchido = false;
            }
            
            if(!this.dados.valorTotal) {
                this.isValidValorTotal = true;
                isPreenchido = false;
            }

            if(!isPreenchido) {
                this.mensagem = {
                    ativo: true,
                    gravidade: 'warn',
                    conteudo: 'Por favor, preencher os campos obrigatórios!'
                }
            }

            return isPreenchido;
        },

        limpar() {
            this.isValidData = false;
            this.isValidPercentual = false;
            this.isValidValorTotal = false;
        },

        limparMensagem() {
            this.mensagem = { ativo: false };
        },

        salvar() {
            if(!this.isCamposObrigatoriosPreenchidos()) return;

            const parcela = Object.assign({}, this.dados);
            this.$emit("salvar", parcela);
            
            this.fechar();
        },

        fechar() {
            this.mensagem = { ativo: false };
            this.$emit("fechar");
            this.limpar();
            this.limparMensagem();
        },

        buscarUnidade(event) {
            if (!event.query.trim().length) {
                this.unidadeFiltro = [...this.unidades];
            } else {
                this.unidadeFiltro = this.unidades.filter((item) => {
                    return item.descricao.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }
        }
    }
}
</script>
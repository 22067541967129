<template>

    <div class="grid formgrid p-3 ml-1 mr-1" style="border: solid 1px #cecece;">
        
        <div class="col-12 sm:col-6 md:col-6 lg:col-6 xl:col-2">  
            <div class="field">
                <label> <strong>Modalidade de frete</strong> </label>
                <div style="border: solid 1px #cecece; height: 30px;">
                    <div class="mt-1 ml-3">
                        <RadioButton class="mr-2" inputId="cif" value="CIF" v-model="dados.modalidadeFrete" :disabled="dados.listaEntregaRetirada != undefined && dados.listaEntregaRetirada.length > 0"/>
                        <label class="mr-4" for="cif">CIF</label>
                        <RadioButton class="ml-3 mr-2" inputId="fob" value="FOB" v-model="dados.modalidadeFrete" :disabled="dados.listaEntregaRetirada != undefined && dados.listaEntregaRetirada.length > 0"/>
                        <label for="fob">FOB</label>
                    </div>
                </div>
            </div>                
        </div>

        <div class="col-12 sm:col-6 md:col-6 lg:col-6 xl:col-2" v-if="dados.modalidadeFrete== 'CIF'">  
            <div class="field"> 
                <label> <strong>Regional Origem Preferencial <span style="color:red;">&nbsp;*</span></strong> </label>
                <AutoComplete
                    class="w-full"
                    placeholder="Selecione..."
                    v-model="dados.localOrigemEntrega"
                    field="label"
                    :suggestions="regionaisFiltradas"
                    :dropdown="true"
                    @complete="buscarEstabelecimento($event)"
                    :class="{ 'w-full p-invalid': campoInvalido.localOrigemEntrega }"
                ></AutoComplete>
            </div>         
        </div>

        <div class="col-12 sm:col-6 md:col-6 lg:col-6 xl:col-2">  
            <div class="field"> 
                <label> <strong>Destino da Mercadoria <span style="color:red;">&nbsp;*</span></strong> </label>
                <AutoComplete      
                    field="label" 
                    class="w-full"                                           
                    :dropdown="true" 
                    @complete="buscarDestinos($event)"
                    placeholder="Selecione..."
                    v-model="dados.destino"
                    :suggestions="destinosFiltrados"
                    :forceSelection="true"
                    :class="{ 'w-full p-invalid': campoInvalido.destino }"
                    ></AutoComplete>  
            </div>         
        </div>

        <div class="col-6 sm:col-3 md:col-3 lg:col-3 xl:col-2">  
            <div class="field">  
                <label><strong>Início Embarque<span style="color:red;">&nbsp;*</span> </strong></label>
                <Calendar
                    class="w-full"
                    v-model="dados.inicioEmbarque"
                    :class="{ 'p-invalid': campoInvalido.inicioEmbarque }"
                    dateFormat="dd/mm/yy"/>
            </div>
        </div>

        <div class="col-6 sm:col-3 md:col-3 lg:col-3 xl:col-2">  
            <div class="field">            
                <label><strong>Fim Embarque<span style="color:red;">&nbsp;*</span></strong></label>
                <Calendar
                    class="w-full"
                    v-model="dados.fimEmbarque"
                    dateFormat="dd/mm/yy"
                    :class="{ 'p-invalid': campoInvalido.fimEmbarque }"
                    :minDate="dados.inicioEmbarque"/>
            </div>
        </div>

        <div class="col-6 sm:col-3 md:col-3 lg:col-3 xl:col-2" v-if="dados.modalidadeFrete=='CIF'">
            <div class="field">
                <label>
                    <strong>Frete Orçado (R$/TON)</strong>
                </label>
                <InputNumber class="w-full" v-model="dados.freteOrcado" mode="decimal" locale="pt-BR" :minFractionDigits="2" :maxFractionDigits="2" :min="0" :max="999999999" />
            </div>
        </div>   
        
    
    </div>

    <DataTable :value="dados.listaEntregaRetirada" showGridlines class="mt-3">
        <template #header>
            <div class="flex align-items-center justify-content-between">                
                <strong ><img src="layout/images/order.png" alt="" style="width: 1.5rem;"/> Entregas/Retiradas</strong>
                <Button icon="pi pi-plus" aria-label="Incluir" @click="abrirModal()"></Button>
            </div>
        </template>

        <Column header="Local de Origem" v-if="dados.modalidadeFrete != 'CIF'">
            <template #body="{ data }">
                {{ data.codEstabOrigemEntrega }} - {{ data.nomeEstabOrigemEntrega }}
            </template>
        </Column>

        <Column header="Destino da Mercadoria">
            <template #body>
                {{ dados.destino?.label }}
            </template>
        </Column>

        <Column header="Quantidade">
            <template #body="{ data }">
                {{ formatDecimal(data.quantidade, 2, 2) }} {{ dados.unidade?.sigla }}
            </template>
        </Column>
        
        <Column header="Data início">
            <template #body="{ data }">
                {{ formatDateCustom(data.dataInicio, "DD/MM/YYYY") }}
            </template>
        </Column>

        <Column header="Data fim">
            <template #body="{ data }">
                {{ formatDateCustom(data.dataFim, "DD/MM/YYYY") }}
            </template>
        </Column>

        <Column header="Tipo de transporte">
            <template #body="{ data }">
                <span v-if="data.tipoTransporte == 'CAMINHAO'">Caminhão</span>
                <span v-if="data.tipoTransporte == 'TREM'">Trem</span>
            </template>
        </Column>

        <Column header="Dados adicionais">
            <template #body="{ data }">
                <Textarea class="w-full" :disabled="true" v-model="data.dadosAdicionais"></Textarea>
            </template>
        </Column>

        <Column header="Ações" style="width:9rem;" class="centralizar-titulo-tabela">
            <template #body="{ index }">
                <div class="flex align-items-center justify-content-center">
                    <Button
                        class="p-button-rounded p-button-info flex-none mr-2"
                        icon="pi pi-pencil"
                        aria-label="Editar"
                        @click="modalEditar(index)"
                    ></Button>

                    <Button 
                        class="p-button-rounded p-button-danger flex-none mr-2"
                        icon="pi pi-trash"
                        aria-label="Excluir"
                        @click="modalExcluir(index)"
                    ></Button>
                </div>
            </template>
        </Column>

        <ColumnGroup type="footer">
            <Row>
                <Column :colspan="2" footerStyle="text-align:right">
                    <template #footer>
                        <strong>
                            Total Geral:
                        </strong>
                    </template>
                </Column>
                <Column>
                    <template #footer>
                        <strong>
                            {{ formatDecimal(quantidadeTotalEntregaRetirada) }} {{ dados.unidade?.sigla }}
                        </strong>
                    </template>
                </Column>
                <Column :colspan="5"></Column>
               
            </Row>
        </ColumnGroup>

    </DataTable>
    <div class="grid align-items-center" style="margin-bottom:-1rem;margin-top:0.5rem">
      <div class="col-2 label">
        <label> <strong>Bloqueio de Pedidos:</strong> </label>
      </div>
      <div class="col-1 campo" style="margin-left:-6.7rem">
        <Checkbox v-model="dados.bloqueioPedidos" :binary="true" />
      </div>
    </div>

    <ModalEntregaRetirada
        :visivel="modalIsVisivel"
        :dados="modalDados"
        :destino="dados.destino"
        :modalidadeFrete="dados.modalidadeFrete"
        :estabelecimentos="estabelecimentos"
        :regionais="regionais"
        @fechar="modalFechar"
        @salvar="modalSalvar"
    />
</template>

<script>
import ProdutorFiltro from '../ProdutorFiltro.vue';
import Formatacao from '../../../utilities/Formatacao';
import ModalEntregaRetirada from './ModalEntregaRetirada.vue';
import MunicipioService from '../../../service/MunicipioService.js';

export default {
    components: { ProdutorFiltro, ModalEntregaRetirada },
    props: {
        dados: {
            type: Object,
            required: true
        },
        estabelecimentos: {
            type: Object,
            required: true
        },
        regionais: {
            type: Object,
            required: true
        },
        campoInvalido: {
            type: Object,
            required: true
        }
    },
    created() {
        this.setup();
    },
    updated() {
        this.setup();
    },
    data() {
        return {
            quantidadeTotalEntregaRetirada: 0,

            regionaisFiltradas: [],
            destinosFiltrados: [],

            modalIsVisivel: false,
            modalDados: {},
        }
    },

    methods: {
        setup() {

            if(!this.dados.modalidadeFrete) {
                this.dados.modalidadeFrete = "CIF";
            }

            if(!this.dados.listaEntregaRetirada) {
                this.dados.listaEntregaRetirada = [];
            }

            this.calcularQuantidadeTotalEntregaRetirada();
        },
        
        /* FUNCIONALIDADES TABELA ADIANTAMENTO - INICIO */
        adiantamentoAction() {
            if(!!this.dados.comAdiantamento) {
            }
            else {
                this.adiantamentos = [];
            }
        },

        buscarEstabelecimento(event) {
            setTimeout(() => {
                if(!event.query.trim().length) {
                    this.regionaisFiltradas = [...this.regionais];
                }
                else {
                    this.regionaisFiltradas = this.regionais.filter((item) => {
                        return item.label.toLowerCase().startsWith(event.query.toLowerCase());
                    });
                }
            }, 250);
        },

        modalSalvar(dados) {
            const indice = dados.indice;

            if(indice >= 0) {
                this.dados.listaEntregaRetirada[indice] = dados;
            }
            else {
                this.dados.listaEntregaRetirada.push(dados);
            }

            this.modalDados = {};

            this.calcularQuantidadeTotalEntregaRetirada();
            
        },

        modalFechar() {
            this.modalIsVisivel = false;
            this.modalDados = {};
        },

        abrirModal(){

            if(this.dados.modalidadeFrete == "CIF"){
                if(!this.dados.localOrigemEntrega){
                    this.$toast.add({ severity: 'warn',life: 15000, summary: 'Atenção!', detail: 'Selecione a Regional de Origem Preferencial' });
                    return;
                }
                this.modalDados["localOrigemEntrega"] = this.dados.localOrigemEntrega;
            }else{                
                this.modalDados["localOrigemEntrega"] = null;
            }

            this.modalIsVisivel = true;
            
        },

        modalEditar(indice) {
            let dados;
            
            dados = Object.assign({}, this.dados.listaEntregaRetirada[indice]);
            
            dados["indice"] = indice;
            if(this.dados.modalidadeFrete == "CIF") {
                dados["localOrigemEntrega"] = this.dados.localOrigemEntrega;
            }else{

                if(!!dados.codRegionalOrigemEntrega){
                    dados["regionalOrigemEntrega"] = this.regionais.find(item => {
                        return item.codigo === dados.codRegionalOrigemEntrega;
                    });
                }

                if(!!dados.codEstabOrigemEntrega){
                    dados["localOrigemEntrega"] = this.estabelecimentos.find(item => {
                        return item.codigo === dados.codEstabOrigemEntrega;
                    });
                }
            }

            dados["dataInicio"] = Formatacao.convertStringToDate(dados.dataInicio, "YYYY-MM-DD");
            dados["dataFim"] = Formatacao.convertStringToDate(dados.dataFim, "YYYY-MM-DD");

            this.modalIsVisivel = true;
            this.modalDados = dados;
        },

        modalExcluir(indice) {
            this.dados.listaEntregaRetirada.splice(indice, 1);
            this.calcularQuantidadeTotalEntregaRetirada();
            
        },
        buscarDestinos(event){
            const codigoOuDescricao = !event.query.trim().length ? 'a' : event.query;
            MunicipioService.buscarPorDescricao(codigoOuDescricao)
            .then(({ data }) => {
                if(!!data && data.length > 0) {
                    this.destinosFiltrados = data.map(municipio => {
                        municipio["label"] = `${ municipio.nome } - ${ municipio.uf }`;
                        return municipio;
                    });
                } else{
                    this.destinosFiltrados =[];
                }
            })
            .catch(error => {
                this.destinosFiltrados = [];
            });
        },

        calcularQuantidadeTotalEntregaRetirada() {
            let entregasRetiradas = [];

            if(this.dados.listaEntregaRetirada !== undefined && this.dados.listaEntregaRetirada !== null && this.dados.listaEntregaRetirada.length > 0) {
                entregasRetiradas = this.dados.listaEntregaRetirada;
            }

            this.quantidadeTotalEntregaRetirada = entregasRetiradas.reduce((total, entrega) => {
                return total + entrega.quantidade;
            }, 0);
        },
        /* FUNCIONALIDADES TABELA ADIANTAMENTO - FIM */

        formatDateCustom(date, format) {
            return Formatacao.formatDateCustom(date, format);
        },

        formatDecimal(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
            return Formatacao.formatDecimal(value, minimumFractionDigits, maximumFractionDigits);
        }
    }
}
</script>

<style>
    .centralizar-titulo-tabela {
        text-align: center !important;
    }

    .centralizar-titulo-tabela .p-column-header-content {
        display: inline;
    }
</style>
<template>
    <!-- VENDEDOR INICIO -->
    <Panel header="" :toggleable="true" :collapsed="true" class="mb-3">
        <template #header>
            <div class="grid p-fluid formgrid">
                <div class="col-12 sm:col-6 md:col-6 lg:col-6 xl:col-6">
                    <div class="field">
                        <label for="empresa">
                            <strong>Vendedor<span style="color:red;">&nbsp;*</span></strong>
                        </label>
                        <ProdutorFiltro :disabled="false" :campoObrigatorio="true" :selecionado="dados.vendedor"
                            :paginacao="vendedorConsulta" :invalid="campoInvalido.vendedor" @pesquisar="pesquisarVendedor"
                            @selecionado="selecionarVendedor" />
                    </div>
                </div>

                <div class="col-12 sm:col-6 md:col-3 lg:col-3 xl:col-3">
                    <div class="field">
                        <label for="empresa">
                            <strong>CPF/CNPJ</strong>
                        </label>
                        <InputText class="w-full uppercase" v-model="dados.vendedor.cpfCnpj" disabled="true" />
                    </div>
                </div>

                <div class="col-12 sm:col-6 md:col-3 lg:col-3 xl:col-3">
                    <div class="field">
                        <label for="empresa">
                            <strong>Representante</strong>
                        </label>
                        <InputText class="w-full uppercase" v-model="dados.vendedor.representante" />
                    </div>
                </div>
            </div>
        </template>
        <div class="grid p-fluid formgrid">
            <div class="col-12 sm:col-6 md:col-9 lg:col-9 xl:col-9">
                <div class="field">
                    <label for="empresa">
                        <strong>Endereço</strong>
                    </label>
                    <InputText class="w-full uppercase" v-model="dados.vendedor.endereco" disabled="true" />
                </div>
            </div>

            <div class="col-12 sm:col-6 md:col-3 lg:col-3 xl:col-3">
                <div class="field">
                    <label for="empresa">
                        <strong>Bairro</strong>
                    </label>
                    <InputText class="w-full uppercase" v-model="dados.vendedor.bairro" disabled="true" />
                </div>
            </div>

            <div class="col-12 sm:col-6 md:col-4 lg:col-4 xl:col-4">
                <div class="field">
                    <label for="empresa">
                        <strong>Cidade</strong>
                    </label>
                    <InputText class="w-full uppercase" v-model="dados.vendedor.cidade" disabled="true" />
                </div>
            </div>

            <div class="col-12 sm:col-6 md:col-2 lg:col-2 xl:col-2">
                <div class="field">
                    <label for="empresa">
                        <strong>Estado</strong>
                    </label>
                    <InputText class="w-full uppercase" v-model="dados.vendedor.estado" disabled="true" />
                </div>
            </div>

            <div class="col-12 sm:col-6 md:col-3 lg:col-3 xl:col-3">
                <div class="field">
                    <label for="empresa">
                        <strong>CEP</strong>
                    </label>
                    <InputText class="w-full uppercase" v-model="dados.vendedor.cep" disabled="true" />
                </div>
            </div>

            <div class="col-12 sm:col-6 md:col-3 lg:col-3 xl:col-3">
                <div class="field">
                    <label for="empresa">
                        <strong>Inscrição Estadual</strong>
                    </label>
                    <InputText class="w-full uppercase" v-model="dados.vendedor.rgIe" disabled="true" />
                </div>
            </div>
        </div>
    </Panel>
    <!-- VENDEDOR FIM -->

    <!-- COMPRADOR INICIO -->
     <Panel header="" :toggleable="true" :collapsed="true" class="mb-3">
        <template #header>
            <div class="grid p-fluid formgrid">
                <div class="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6">
                    <div class="field">
                        <label for="empresa">
                            <strong>Comprador<span style="color:red;">&nbsp;*</span></strong>
                        </label>
                        <ProdutorFiltro :disabled="false" :campoObrigatorio="true" :selecionado="dados.comprador"
                            :paginacao="compradorConsulta" :invalid="campoInvalido.comprador"
                            @pesquisar="pesquisarComprador" @selecionado="selecionarComprador" />
                    </div>
                </div>

                <div class="col-12 sm:col-6 md:col-3 lg:col-3 xl:col-3">
                    <div class="field">
                        <label for="empresa">
                            <strong>CPF/CNPJ</strong>
                        </label>
                        <InputText class="w-full uppercase" v-model="dados.comprador.cpfCnpj" disabled="true" />
                    </div>
                </div>

                <div class="col-12 sm:col-6 md:col-3 lg:col-3 xl:col-3">
                    <div class="field">
                        <label for="empresa">
                            <strong>Representante</strong>
                        </label>
                        <InputText class="w-full uppercase" />
                    </div>
                </div>
            </div>
        </template>
        <div class="grid p-fluid formgrid">
            <div class="col-12 sm:col-6 md:col-9 lg:col-9 xl:col-9">
                <div class="field">
                    <label for="empresa">
                        <strong>Endereço</strong>
                    </label>
                    <InputText class="w-full uppercase" v-model="dados.comprador.endereco" disabled="true" />
                </div>
            </div>

            <div class="col-12 sm:col-6 md:col-3 lg:col-3 xl:col-3">
                <div class="field">
                    <label for="empresa">
                        <strong>Bairro</strong>
                    </label>
                    <InputText class="w-full uppercase" v-model="dados.comprador.bairro" disabled="true" />
                </div>
            </div>

            <div class="col-12 sm:col-6 md:col-4 lg:col-4 xl:col-4">
                <div class="field">
                    <label for="empresa">
                        <strong>Cidade</strong>
                    </label>
                    <InputText class="w-full uppercase" v-model="dados.comprador.cidade" disabled="true" />
                </div>
            </div>

            <div class="col-12 sm:col-6 md:col-2 lg:col-2 xl:col-2">
                <div class="field">
                    <label for="empresa">
                        <strong>Estado</strong>
                    </label>
                    <InputText class="w-full uppercase" v-model="dados.comprador.estado" disabled="true" />
                </div>
            </div>

            <div class="col-12 sm:col-6 md:col-3 lg:col-3 xl:col-3">
                <div class="field">
                    <label for="empresa">
                        <strong>CEP</strong>
                    </label>
                    <InputText class="w-full uppercase" v-model="dados.comprador.cep" disabled="true" />
                </div>
            </div>

            <div class="col-12 sm:col-6 md:col-3 lg:col-3 xl:col-3">
                <div class="field">
                    <label for="empresa">
                        <strong>Inscrição Estadual</strong>
                    </label>
                    <InputText class="w-full uppercase" v-model="dados.comprador.rgIe" disabled="true" />
                </div>
            </div>
        </div>
    </Panel>
    <!-- COMPRADOR FIM -->

    <!-- MERCADORIA INICIO -->
    <Fieldset legend="Mercadoria">
        <div class="grid p-fluid formgrid">

            <div class="col-12 sm:col-6 md:col-4 lg:col-4 xl:col-4">
                <div class="field">
                    <label for="grupo">
                        <strong>Grupo Produto<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <AutoComplete :dropdown="true" class="w-full" :class="{ 'p-invalid': campoInvalido.grupoProduto }"
                        placeholder="Selecione..." field="codigoDescricao" v-model="dados.grupoProduto"
                        :forceSelection="true" :suggestions="grupoProdutoFiltro" @complete="buscarGrupoProduto"
                        @item-select="grupoProdutoSelecionar" />
                </div>
            </div>

            <div class="col-12 sm:col-6 md:col-2 lg:col-2 xl:col-2">
                <div class="field">
                    <label for="quantidade">
                        <strong>Quantidade<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <InputNumber v-model="dados.quantidade" id="quantidade" locale="pt-BR" :max="99999999999999"
                        :minFractionDigits="2" :maxFractionDigits="2"
                        :class="{ 'p-invalid': campoInvalido.quantidade }" />
                </div>
            </div>

            <div class="col-12 sm:col-6 md:col-2 lg:col-2 xl:col-2">
                <div class="field">
                    <label for="unidade">
                        <strong>Unidade<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <AutoComplete dropdown placeholder="Selecione..." v-model="dados.unidade" field="descricao"
                        id="unidade" class="w-full" :class="{ 'p-invalid': campoInvalido.unidade }"
                        :suggestions="unidadeFiltro" :forceSelection="true" @complete="buscarUnidade" />
                </div>
            </div>

            <div class="col-12 sm:col-6 md:col-2 lg:col-2 xl:col-2">
                <div class="field">
                    <label for="safra">
                        <strong>Safra<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <AutoComplete dropdown placeholder="Selecione..." v-model="dados.safra" field="safraComposta"
                        id="safra" class="w-full" :class="{ 'p-invalid': campoInvalido.safra }"
                        :suggestions="safraCompostaFiltro" :disabled="!dados.grupoProduto" :forceSelection="true"
                        @complete="buscarSafraComposta" />
                </div>
            </div>

            <div class="col-12 sm:col-6 md:col-2 lg:col-2 xl:col-2">
                <div class="field">
                    <label for="tipo-tributacao">
                        <strong>Tipo Tributação<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <AutoComplete dropdown placeholder="Selecione..." v-model="dados.tipoTributacao" field="descricao"
                        id="tipo-tributacao" class="w-full" :class="{ 'p-invalid': campoInvalido.tipoTributacao }"
                        :suggestions="tipoTributacaoFiltro" :forceSelection="true" @complete="buscarTipoTributacao" />
                </div>
            </div>

            <div class="col-12 sm:col-6 md:col-2 lg:col-2 xl:col-2">
                <div class="field">
                    <label for="limite_umidade">
                        <strong>Limite de Umidade (%)<span style="color:red;" v-if="dados.possuiUmidade">&nbsp;*</span></strong>
                    </label>
                    <InputNumber v-model="dados.limiteUmidade" id="limite_umidade" locale="pt-BR"
                        :class="{ 'p-invalid': campoInvalido.limiteUmidade }" :max="9999999" :minFractionDigits="1"
                        :maxFractionDigits="1" :disabled="!dados.possuiUmidade || dados.possuiUmidade == undefined || dados.possuiUmidade == null" />
                </div>
            </div>

            <div class="col-12 sm:col-6 md:col-2 lg:col-2 xl:col-2">
                <div class="field">
                    <label for="limite_impureza">
                        <strong>Limite de Impureza (%)<span style="color:red;" v-if="dados.possuiImpureza">&nbsp;*</span></strong>
                    </label>
                    <InputNumber v-model="dados.limiteImpureza" id="limite_impureza" locale="pt-BR"
                        :class="{ 'p-invalid': campoInvalido.limiteImpureza }" :max="9999999" :minFractionDigits="1"
                        :maxFractionDigits="1" :disabled="!dados.possuiImpureza || dados.possuiImpureza == undefined || dados.possuiImpureza == null" />
                </div>
            </div>

            <div class="col-12 sm:col-6 md:col-2 lg:col-2 xl:col-2">
                <div class="field">
                    <label for="limite_grao_avariado">
                        <strong>Limite Grão Avariado (%)<span style="color:red;" v-if="dados.possuiChuvadoAvariado">&nbsp;*</span></strong>
                    </label>
                    <InputNumber v-model="dados.limiteGraoAvariado" id="limite_grao_avariado" locale="pt-BR"
                        :class="{ 'p-invalid': campoInvalido.limiteGraoAvariado }" :max="9999999" :minFractionDigits="1"
                        :maxFractionDigits="1" :disabled="!dados.possuiChuvadoAvariado || dados.possuiChuvadoAvariado == undefined || dados.possuiChuvadoAvariado == null" />
                </div>
            </div>

            <div class="col-12 sm:col-6 md:col-2 lg:col-2 xl:col-2">
                <div class="field">
                    <label for="limite_tbm">
                        <strong>Limite de TBM (%)<span style="color:red;" v-if="dados.possuiTbm">&nbsp;*</span></strong>
                    </label>
                    <InputNumber v-model="dados.limiteTbm" id="limite_tbm" locale="pt-BR"
                        :class="{ 'p-invalid': campoInvalido.limiteTbm }" :max="9999999" :minFractionDigits="1"
                        :maxFractionDigits="1" :disabled="!dados.possuiTbm || dados.possuiTbm == undefined || dados.possuiTbm == null" />
                </div>
            </div>

            <div class="col-12 sm:col-6 md:col-2 lg:col-2 xl:col-2">
                <div class="field">
                    <label for="limite_ph">
                        <strong>Limite de PH (%)<span style="color:red;" v-if="dados.possuiPh">&nbsp;*</span></strong>
                    </label>
                    <InputNumber v-model="dados.limitePh" id="limite_ph" locale="pt-BR"
                        :class="{ 'p-invalid': campoInvalido.limitePh }" :max="9999999" :minFractionDigits="1"
                        :maxFractionDigits="1" :disabled="!dados.possuiPh || dados.possuiPh == undefined || dados.possuiPh == null" />
                </div>
            </div>

            <div class="col-12 sm:col-6 md:col-2 lg:col-2 xl:col-2">
                <div class="field">
                    <label for="limite_bandinha">
                        <strong>Limite de Bandinha (%)<span style="color:red;" v-if="dados.possuiBandinha">&nbsp;*</span></strong>
                    </label>
                    <InputNumber v-model="dados.limiteBandinha" id="limite_bandinha" locale="pt-BR"
                        :class="{ 'p-invalid': campoInvalido.limiteBandinha }" :max="9999999" :minFractionDigits="1"
                        :maxFractionDigits="1" :disabled="!dados.possuiBandinha || dados.possuiBandinha == undefined || dados.possuiBandinha == null" />
                </div>
            </div>

            <div class="col-12 sm:col-6 md:col-2 lg:col-2 xl:col-2">
                <div class="field">
                    <label for="valorSeguro">
                        <strong>Valor Seguro (R$/Saca)</strong>
                    </label>
                    <InputNumber v-model="dados.valorSeguro" id="valorSeguro" locale="pt-BR" :max="9999999" :minFractionDigits="2" :maxFractionDigits="2" />
                </div>
            </div>

            <div class="col-12 sm:col-6 md:col-2 lg:col-2 xl:col-2">
                <div class="field">
                    <label> <strong>Destino Mercadoria<span style="color:red;">&nbsp;*</span></strong> </label>
                    <AutoComplete
                        dropdown
                        placeholder="Selecione..."
                        v-model="dados.destinoMercadoria"
                        field="descricao"
                        id="destinoMercadoria"
                        class="w-full"
                        :class="{ 'p-invalid': campoInvalido.destinoMercadoria }"
                        :suggestions="destinoMercadoriaFiltro"
                        :forceSelection="true"
                        @complete="buscarDestinoMercadoria"
                    />
                </div>
            </div>

            <div class="col-12 sm:col-12 md:col-4 lg:col-4 xl:col-4">

                <div class="field">
                    <label> <strong>Natureza Operação<span style="color:red;">&nbsp;*</span></strong> </label>

                    <NaturezaOperacaoZoom
                        codigo="codigo"
                        descricao="descricao"
                        :selecionado="dados.naturezaOperacao"
                        :disabled="false"
                        :invalid="campoInvalido.naturezaOperacao"
                        :paginacao="naturezaOperacaoConsulta"
                        @pesquisar="pesquisarNaturezaOperacao"
                        @selecionado="selecionarNaturezaOperacao"
                    />

                </div>
            </div>

            <div class="col-12 sm:col-4 md:col-4 lg:col-4 xl:col-4"></div>

            <div class="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6">
                <div class="field">
                    <label for="outraClassificacoes">
                        <strong>Outras Classificações</strong>
                    </label>
                    <Textarea class="w-full" id="outraClassificacoes" rows="4" v-model="dados.outrasClassificacoes"></Textarea>
                </div>
            </div>

            <div class="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6">
                <div class="field">
                    <label for="observacao">
                        <strong>Observação NF</strong>
                    </label>
                    <Textarea class="w-full" id="observacao" rows="4" v-model="dados.observacaoMercadoria"></Textarea>
                </div>
            </div>

        </div>
    </Fieldset>
    <!-- MERCADORIA FIM -->
</template>

<script>
import NaturezaOperacaoZoom from '../../zoom/NaturezaOperacaoZoom.vue';
import ProdutorFiltro from '../ProdutorFiltro.vue';
import SafraCompostaService from '../../../service/SafraCompostaService';
import ProdutorService from '../../../service/ProdutorService';
import UnidadeMedidaService from './service/UnidadeMedidaService';
import TipoProdutoService from '../../../service/TipoProdutoService';
import GrupoProdutoService from '../../../service/GrupoProdutoService';
import ContratoCompraVendaService from '../../../service/ContratoCompraVendaService';
import DestinoMercadoriaService from '../../../service/DestinoMercadoriaService';
import NaturezaOperacaoVgService from '../../../service/NaturezaOperacaoVgService';

export default {
    components: { ProdutorFiltro, NaturezaOperacaoZoom},
    props: {
        dados: {
            type: Object,
            required: true
        },
        campoInvalido: {
            type: Object,
            required: true
        }
    },
    data() {
        return {

            produtoresFiltro: [],
            produtorConsulta: {},

            grupoProdutoFiltro: [],

            safraCompostaFiltro: [],
            safraCompostas: [],

            unidadeFiltro: [],
            unidades: UnidadeMedidaService.lista(),

            vendedorConsulta: {},
            compradorConsulta: {},

            destinoMercadoriaFiltro: [],
            destinoMercadoriaLista: [],

            tipoTributacaoLista: [],
            tipoTributacaoFiltro: [],

            naturezaOperacaoConsulta: {}
        }
    },

    created() {
        this.carregarTipoTributacao();
        this.carregarDestinoMercadoria();

        if (!this.dados.vendedor) {
            this.dados.vendedor = {};
        }

        if (!this.dados.comprador) {
            this.dados.comprador = {};
        }
    },

    updated() {
            
    },

    methods: {
        buscarGrupoProduto(event) {
            const filtro = !event.query.trim().length ? undefined : event.query.trim();
            GrupoProdutoService.buscarPorCodigoOuDescricaoComParametroEntradaReDiferenteNaoPermite(filtro)
                .then(({ data }) => {
                    if (!!data) {
                        this.grupoProdutoFiltro = [...data];
                    }
                })
                .catch(error => {
                    const toast = {
                        severity: 'error',
                        summary: 'Falha ao carregar os tipos de produto.',
                        detail: error,
                        life: 7500
                    }

                    if (!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;
                        toast["detail"] = message;
                    }

                    this.$toast.add(toast);
                    this.grupoProdutoFiltro = [];
                });
        },

        grupoProdutoSelecionar() {
            this.dados.safra = null;
            this.dados.tipoProduto = null;
            this.carregarSafraComposta();
            this.preencherTipoProduto();

            if(!!this.dados.grupoProduto) {
                GrupoProdutoService.buscarPorCodigo(this.dados.grupoProduto.fmCodigo)
                    .then(async ({ data }) => {
                        this.dados["possuiTbm"] = data.tbm;
                        this.dados["possuiPh"] = data.phEntrada;
                        this.dados["possuiUmidade"] = data.umidade;
                        this.dados["possuiImpureza"] = data.impureza;
                        this.dados["possuiBandinha"] = data.logBandinha;
                        this.dados["possuiChuvadoAvariado"] = data.chuvAvar;

                        if(!!data.tbm) this.dados["limiteTbm"] = null;
                        if(!!data.phEntrada) this.dados["limitePh"] = null;
                        if(!!data.umidade) this.dados["limiteUmidade"] = null;
                        if(!!data.impureza) this.dados["limiteImpureza"] = null;
                        if(!!data.logBandinha) this.dados["limiteBandinha"] = null;
                        if(!!data.chuvAvar) this.dados["limiteGraoAvariado"] = null;
                    });
            }
        },

        buscarUnidade(event) {
            if (!event.query.trim().length) {
                this.unidadeFiltro = [...this.unidades];
            } else {
                this.unidadeFiltro = this.unidades.filter((item) => {
                    return item.descricao.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }
        },

        preencherTipoProduto() {
            const fmCodigo = this.dados.grupoProduto.fmCodigo;
            TipoProdutoService.buscarPorFmCodigo(fmCodigo)
                .then(({ data }) => {
                    this.dados.tipoProduto = data;
                })
                .catch(error => {
                    this.dados.grupoProduto = null;
                    const toast = {
                        severity: 'error',
                        summary: 'Falha ao preencher o tipo de produto.',
                        detail: error,
                        life: 7500
                    }

                    if (!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;
                        toast["detail"] = message;
                    }

                    this.$toast.add(toast);
                });
        },

        carregarSafraComposta() {
            const fmCodigo = this.dados.grupoProduto.fmCodigo;
            SafraCompostaService.buscarPorFmCodigoAnoBase(fmCodigo)
                .then(({ data }) => {
                    if (!!data) {
                        this.safraCompostas = data;
                    }
                })
                .catch(error => {
                    const toast = {
                        severity: 'error',
                        summary: 'Falha ao carregar as safras.',
                        detail: error,
                        life: 7500
                    }

                    if (!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;
                        toast["detail"] = message;
                    }

                    this.$toast.add(toast);
                });
        },

        buscarSafraComposta(event) {
            if (!event.query.trim().length) {
                this.safraCompostaFiltro = [...this.safraCompostas];
            } else {
                this.safraCompostaFiltro = this.safraCompostas.filter((item) => {
                    return item.safraComposta.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }
        },

        carregarTipoTributacao() {
            ContratoCompraVendaService.tipoTributacao()
                .then(({ data }) => {
                    this.tipoTributacaoLista = data;
                })
                .catch(error => {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Falha ao carregar os tipos de tributação.',
                        detail: error,
                        life: 7500
                    });
                });
        },

        buscarTipoTributacao(event) {
            if (!event.query.trim().length) {
                this.tipoTributacaoFiltro = [...this.tipoTributacaoLista];
            } else {
                this.tipoTributacaoFiltro = this.tipoTributacaoLista.filter((item) => {
                    return item.descricao.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }
        },

        pesquisarVendedor(filtros) {
            ProdutorService.pesquisarProdutor(filtros)
                .then(({ data }) => {
                    if (data) {
                        this.vendedorConsulta = data;
                    }
                })
                .catch(error => {
                    this.vendedorConsulta = {}

                    const toast = {
                        severity: 'error',
                        summary: 'Falha ao carregar os vendedores.',
                        detail: error,
                        life: 7500
                    }

                    if (!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;
                        toast["detail"] = message;
                    }

                    this.$toast.add(toast);
                });
        },

        selecionarVendedor(codProdutor) {
            if (codProdutor == undefined || codProdutor == null || codProdutor.trim() == "") {
                this.dados.vendedor = {};
                return;
            }

            ProdutorService.buscarPorCodProdutorInfoContrato(codProdutor)
                .then(({ data }) => {
                    if (data) {
                        this.dados.vendedor = data;
                    }
                })
                .catch(error => {
                    this.vendedorConsulta = {}

                    const toast = {
                        severity: 'error',
                        summary: `Falha ao buscar o vendedor.`,
                        detail: error,
                        life: 7500
                    }

                    if (!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;

                        if (!!message) {
                            toast["detail"] = message.replace("produtor", "vendedor");
                        }
                    }

                    this.$toast.add(toast);
                });
        },

        pesquisarComprador(filtros) {
            ProdutorService.pesquisarProdutor(filtros)
                .then(({ data }) => {
                    if (data) {
                        this.compradorConsulta = data;
                    }
                })
                .catch(error => {
                    this.compradorConsulta = {}

                    const toast = {
                        severity: 'error',
                        summary: 'Falha ao carregar os compradores.',
                        detail: error,
                        life: 7500
                    }

                    if (!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;
                        toast["detail"] = message;
                    }

                    this.$toast.add(toast);
                });
        },

        selecionarComprador(codProdutor) {
            if (codProdutor == undefined || codProdutor == null || codProdutor.trim() == "") {
                this.dados.comprador = {};
                return;
            }

            ProdutorService.buscarPorCodProdutorInfoContrato(codProdutor)
                .then(({ data }) => {
                    if (data) {
                        this.dados.comprador = data;
                    }
                })
                .catch(error => {
                    this.dados.comprador = {};

                    const toast = {
                        severity: 'error',
                        summary: `Falha ao buscar o comprador.`,
                        detail: error,
                        life: 7500
                    }

                    if (!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;

                        if (!!message) {
                            toast["detail"] = message.replace("produtor", "comprador");
                        }
                    }

                    this.$toast.add(toast);
                });
        },

        carregarDestinoMercadoria() {
            DestinoMercadoriaService.lista()
                .then(({ data }) => {
                    this.destinoMercadoriaLista = data;
                });
        },

        buscarDestinoMercadoria(event) {
            if (!event.query.trim().length) {
                this.destinoMercadoriaFiltro = [...this.destinoMercadoriaLista];
            } else {
                this.destinoMercadoriaFiltro = this.destinoMercadoriaLista.filter((item) => {
                    return item.descricao.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }
        },

        pesquisarNaturezaOperacao(filtros) {
            NaturezaOperacaoVgService.buscar(filtros)
                .then(({ data }) => {
                    if(data) {
                        this.naturezaOperacaoConsulta = data.naturezas;
                    }
                })
                .catch(error => {
                    this.naturezaOperacaoConsulta = {}

                    const toast = {
                        severity: 'error',
                        summary: 'Falha ao carregar as naturezas.',
                        detail: "Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!", 
                        life: 7500
                    }

                    if(!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;
                        toast["detail"] = message;
                    }

                    this.$toast.add(toast);
                });
        },

        selecionarNaturezaOperacao(natureza) {

            if(natureza == undefined || natureza == null || natureza.trim() == "") {
                this.dados.naturezaOperacao = {};
                return;
            }

            NaturezaOperacaoVgService.buscarPorNatureza(natureza)
                .then(({ data }) => {
                    const naturezaOperacao = {};

                    if(data.natureza.ativo){
                        naturezaOperacao["codigo"] = data.natureza.natureza;
                        naturezaOperacao["descricao"] = data.natureza.denominacao;

                    }else{
                        const toast = {
                            severity: 'warn',
                            summary: `Atenção!`,
                            detail: "A natureza selecionada está inativa e não pode ser utilizada", 
                            life: 7500
                        }
                        this.$toast.add(toast);
                    }

                    this.dados.naturezaOperacao = naturezaOperacao;
                })
                .catch(error => {
                    this.dados.naturezaOperacao = {};

                    const toast = {
                        severity: 'error',
                        summary: `Falha ao buscar a natureza de operação.`,
                        detail: "Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!", 
                        life: 7500
                    }

                    if(!!error && !!error.response && !!error.response.data) {
                        const { message, exception } = error.response.data;

                        if(!!message) {
                            toast["detail"] = exception;
                        }
                    }

                    this.$toast.add(toast);
                });
        },
    }
}
</script>
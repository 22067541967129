import axios from "axios";
import ApiUrl from "../../../../config/ApiUrl";

class CondicaoPagamentoService {    

    lista() {
        return axios.get(`${ ApiUrl.vg }api/genesis-vg/v1/condicaopagamento/listar`);
    }

    buscarPorDescricao(descricao) {
        return axios.get(`${ ApiUrl.vg }api/genesis-vg/v1/condicaopagamento/buscar-por-descricao/${ descricao }`);
    }
    
}

export default new CondicaoPagamentoService();
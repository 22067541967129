<template>

    <div class="flex align-items-center m-2">
        <strong style="font-size: 14px;">Modalidade de precificação:</strong>
        <div class="flex align-items-center ml-3">
            <RadioButton inputId="fixo" value="FIXO" v-model="dados.modalidadePrecificacao" @click="verifica(1)"/>
            <label for="fixo" class="ml-2">Fixo</label>
        </div>
        <div class="flex align-items-center ml-3">
          <RadioButton inputId="fixo_dolar" value="FIXO_DOLAR" v-model="dados.modalidadePrecificacao" @click="verifica(2)"/>
          <label for="fixo_dolar" class="ml-2">Fixo em Dólar</label>
        </div>
        <div class="flex align-items-center ml-3">
            <RadioButton inputId="a_fixar" value="A_FIXAR" v-model="dados.modalidadePrecificacao" @click="verifica(3)"/>
            <label for="a_fixar" class="ml-2">A Fixar</label>
        </div>
    </div>

    <!-- MODALIDADE DE PREÇO FIXO INICIO -->
    <Fieldset v-if="dados.modalidadePrecificacao == 'FIXO'" legend="Modalidade de preço fixo" class="mt-3">
        <div class="grid p-fluid formgrid">

            <div class="col-12 sm:col-6 md:col-3 lg:col-3 xl:col-3">
                <div class="field">
                    <label for="preco_unitario">
                        <strong>Preço Unitário<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <InputNumber
                        v-model="dados.precoUnitario"
                        id="preco_unitario"
                        locale="pt-BR"
                        :max="9999999"
                        :minFractionDigits="2"
                        :maxFractionDigits="2"
                        :class="{ 'p-invalid': campoInvalido.precoUnitario }"
                        @focusout="incluirParcela"
                    />
                </div>
            </div>

            <div class="col-12 sm:col-6 md:col-3 lg:col-3 xl:col-3">
                <div class="field">
                    <label for="unidade">
                        <strong>Unidade<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <AutoComplete
                        placeholder="Selecione..."
                        dropdown
                        v-model="dados.unidadePrecoFixo"
                        field="descricao"
                        id="unidade"
                        class="w-full"
                        :class="{ 'p-invalid': campoInvalido.unidadePrecoFixo }"
                        :suggestions="unidadeFiltro"
                        :forceSelection="true"
                        @complete="buscarUnidade"
                        @item-select="sugerirFatorConversao"
                    />
                </div>
            </div>

            <div class="col-12 sm:col-6 md:col-3 lg:col-3 xl:col-3">
                <div class="field">
                    <label for="fator_conversao">
                        <strong>Fator de conversão<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <InputNumber
                        v-model="dados.fatorConversao"
                        id="fator_conversao"
                        locale="pt-BR"
                        suffix=" Kg"
                        :max="9999999"
                        :minFractionDigits="2"
                        :maxFractionDigits="2"
                        :class="{ 'p-invalid': campoInvalido.fatorConversao }"
                    />
                </div>
            </div>
        </div>
    </Fieldset>
    <!-- MODALIDADE DE PREÇO FIXO FIM -->

  <!-- MODALIDADE DE PREÇO A FIXO EM DOLAR -->
  <Fieldset v-if="dados.modalidadePrecificacao == 'FIXO_DOLAR'" legend="Modalidade de preço fixo em dólar" class="mt-3">
    <div class="grid p-fluid formgrid">
      <div class="col-3 sm:col-3 md:col-3 lg:col-3 xl:col-3">
        <div class="field">
          <label for="regra-composicao">
            <strong>Regra<span style="color:red;">&nbsp;*</span></strong>
          </label>
          <AutoComplete
              placeholder="Selecione..."
              dropdown
              :disabled="dados.tipoFixacao=='FIXO_DOLAR'"
              v-model="dados.composicaoValor"
              field="descricao"
              id="regra-composicao"
              class="w-full"
              :class="{ 'p-invalid': campoInvalido.composicaoValor }"
              :suggestions="regraComposicaoFiltro"
              :forceSelection="true"
              @complete="buscarRegrasComposicao"
              @item-select="preencherTabelaRegrasComConfiguracaoIndicadores"
          />
        </div>
      </div>

      <div class="col-12 sm:col-4 md:col-4 lg:col-2 xl:col-2">
        <div class="field">
          <label for="data-fim-fixaxao">
            <strong>Data Fim Fixação<span style="color:red;">&nbsp;*</span></strong>
          </label>
          <Calendar
              v-model="dados.dataFimFixacao"
              id="data-fim-fixaxao"
              dateFormat="dd/mm/yy"
              class="w-full"
              :class="{ 'p-invalid': campoInvalido.dataFimFixacao }"
          />
        </div>
      </div>

      <div class="col-12 sm:col-5 md:col-4 lg:col-3 xl:col-2">
        <div class="field">
          <label for="preço-unitario-dolar">
            <strong>Preço Unitário (Dólar)<span style="color:red;">&nbsp;*</span></strong>
          </label>
          <InputNumber
              class="w-full"
              v-model="dados.precoUnitario"
              id="preço-unitario-dolar"
              locale="pt-BR"
              :max="9999999"
              :minFractionDigits="2"
              :maxFractionDigits="2"
              :class="{ 'p-invalid': campoInvalido.precoUnitario }"
              @focusout="incluirParcela"
          />
        </div>
      </div>

        <div class="col-12 sm:col-6 md:col-3 lg:col-3 xl:col-3">
          <div class="field">
            <label for="unidade">
              <strong>Unidade<span style="color:red;">&nbsp;*</span></strong>
            </label>
            <AutoComplete
                placeholder="Selecione..."
                dropdown
                v-model="dados.unidadePrecoFixo"
                field="descricao"
                id="unidade"
                class="w-full"
                :class="{ 'p-invalid': campoInvalido.unidadePrecoFixo }"
                :suggestions="unidadeFiltro"
                :forceSelection="true"
                @complete="buscarUnidade"
                @item-select="sugerirFatorConversao"
            />
          </div>
        </div>

    </div>

    <div class="grid p-fluid formgrid">
      <div class="col-12 sm:col-12 md:col-12 lg:col-10 xl:col-10 mb-3">
        <DataTable :value="dados.regras" showGridlines>
          <template #header>
            <div class="flex align-items-center justify-content-between">
              <strong style="font-size: 14px;">Configuração de Indicadores</strong>
            </div>
          </template>

          <Column field="regra" header="Descrição">
            <template #body="{ data, field }">
              {{ data[field] }}
            </template>
          </Column>

          <Column field="moedaDescricao" header="Moeda" style="width: 8rem;">
            <template #body="{ data, field }">
              {{ data[field] }}
            </template>
          </Column>

          <Column field="valorPadrao" style="width: 10rem;">
            <template #header>
              Valor Padrão<span style="color:red;">&nbsp;*</span>
            </template>

            <template #body="{ data, field }">
              <InputNumber
                  class="w-full"
                  v-model="data[field]"
                  locale="pt-BR"
                  :max="9999999"
                  :minFractionDigits="2"
                  :maxFractionDigits="2"
                  :disabled="data['desativar']"
                  :class="{ 'p-invalid': campoInvalido.configuracaoIndicadores && !data[field] }"
              />
            </template>
          </Column>

          <Column field="dataBase" style="width: 10rem;">
            <template #header>
              Mês/Ano Base<span style="color:red;">&nbsp;*</span>
            </template>

            <template #body="{ data, field }">
              <Calendar
                  v-model="data[field]"
                  dateFormat="MM/yy"
                  class="w-full"
                  view="month"
                  :class="{ 'p-invalid': campoInvalido.configuracaoIndicadores && !data[field] }"
              />
            </template>
          </Column>

          <Column field="fim" style="width: 10rem;">
            <template #header>
              Fim<span style="color:red;">&nbsp;*</span>
            </template>

            <template #body="{ data, field }">
              <Calendar
                  v-model="data[field]"
                  dateFormat="dd/mm/yy"
                  class="w-full"
                  :class="{ 'p-invalid': campoInvalido.configuracaoIndicadores && !data[field] }"
              />
            </template>
          </Column>
        </DataTable>
      </div>

      <div class="col-12 sm:col-12 md:col-12 lg:col-2 xl:col-2 mb-3">
        <div style="border: 1px solid #e4e4e4; height: 100%;">
          <div class="col-12">
            <div class="flex align-items-center justify-content-between" style="padding: 0.7rem 0 0.8rem 0;">
              <strong style="font-size: 14px;">Fixação<span style="color:red;">&nbsp;*</span></strong>
            </div>
          </div>

          <div class="col-12" hidden>
            <div class="field">
              <div class="flex align-items-center">
                <RadioButton v-model="dados.tipoFixacao" id="antecipada" value="ANTECIPADA" :class="{ 'p-invalid': campoInvalido.tipoFixacao }" />
                <label for="antecipada" class="ml-2">Antecipada</label>
              </div>
            </div>
          </div>

          <div class="col-12" hidden>
            <div class="field">
              <div class="flex align-items-center">
                <RadioButton v-model="dados.tipoFixacao" id="embarque" value="EMBARQUE" :class="{ 'p-invalid': campoInvalido.tipoFixacao }" />
                <label for="embarque" class="ml-2">Embarque</label>
              </div>
            </div>
          </div>

          <div class="col-12" hidden>
            <div class="field">
              <div class="flex align-items-center">
                <RadioButton v-model="dados.tipoFixacao" id="antecipada-embarque" value="ANTECIPADA_EMBARQUE" :class="{ 'p-invalid': campoInvalido.tipoFixacao }" />
                <label for="antecipada-embarque" class="ml-2">Antecipada + Embarque</label>
              </div>
            </div>
          </div>

          <div class="col-12">
            <div class="field">
              <div class="flex align-items-center">
                <RadioButton v-model="dados.tipoFixacao" id="fixo-dolar-option" value="FIXO_DOLAR" :class="{ 'p-invalid': campoInvalido.tipoFixacao }" />
                <label for="fixo-dolar" class="ml-2">Fixo em Dólar</label>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="col-12">
        <div class="field">
          <label for="detalhamento_regra">
            <strong>Detalhamento da regra</strong>
          </label>
          <Textarea class="w-full" v-model="dados.detalhamentoRegra"></Textarea>
        </div>
      </div>
    </div>
  </Fieldset>
  <!-- MODALIDADE DE PREÇO A FIXO EM DOLAR FIM -->

    <!-- MODALIDADE DE PREÇO A FIXAR INICIO -->
    <Fieldset v-if="dados.modalidadePrecificacao == 'A_FIXAR'" legend="Modalidade de preço a fixar" class="mt-3">
        <div class="grid p-fluid formgrid">
            <div class="col-12 sm:col-8 md:col-8 lg:col-4 xl:col-6">
                <div class="field">
                    <label for="regra-composicao">
                        <strong>Regra<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <AutoComplete
                        placeholder="Selecione..."
                        dropdown
                        v-model="dados.composicaoValor"
                        field="descricao"
                        id="regra-composicao"
                        class="w-full"
                        :class="{ 'p-invalid': campoInvalido.composicaoValor }"
                        :suggestions="regraComposicaoFiltro"
                        :forceSelection="true"
                        @complete="buscarRegrasComposicao"
                        @item-select="preencherTabelaRegrasComConfiguracaoIndicadores"
                    />
                </div>
            </div>

            <div class="col-12 sm:col-4 md:col-4 lg:col-2 xl:col-2">
                <div class="field">
                    <label for="data-fim-fixaxao">
                        <strong>Data Fim Fixação<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <Calendar
                        v-model="dados.dataFimFixacao"
                        id="data-fim-fixaxao"
                        dateFormat="dd/mm/yy"
                        class="w-full"
                        :class="{ 'p-invalid': campoInvalido.dataFimFixacao }"
                    />
                </div>
            </div>

            <div class="col-12 sm:col-5 md:col-4 lg:col-3 xl:col-2">
                <div class="field">
                    <label for="valor-faturamento-simbolico">
                        <strong>Vlr. Fat. Simbólico (R$/SC)<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <InputNumber
                        class="w-full"
                        v-model="dados.valorFaturamentoSimbolico"
                        id="valor-faturamento-simbolico"
                        locale="pt-BR"
                        :max="9999999"
                        :minFractionDigits="2"
                        :maxFractionDigits="2"
                        :class="{ 'p-invalid': campoInvalido.valorFaturamentoSimbolico }"
                        @focusout="incluirParcela"
                    />
                </div>
            </div>

        </div>

        <div class="grid p-fluid formgrid">
            <div class="col-12 sm:col-12 md:col-12 lg:col-10 xl:col-10 mb-3">
                <DataTable :value="dados.regras" showGridlines>
                    <template #header>
                        <div class="flex align-items-center justify-content-between">
                            <strong style="font-size: 14px;">Configuração de Indicadores</strong>
                        </div>
                    </template>

                    <Column field="regra" header="Descrição">
                        <template #body="{ data, field }">
                            {{ data[field] }}
                        </template>
                    </Column>

                    <Column field="moedaDescricao" header="Moeda" style="width: 8rem;">
                        <template #body="{ data, field }">
                            {{ data[field] }}
                        </template>
                    </Column>

                    <Column field="valorPadrao" style="width: 10rem;">
                        <template #header>
                            Valor Padrão<span style="color:red;">&nbsp;*</span>
                        </template>

                        <template #body="{ data, field }">
                            <InputNumber
                                class="w-full"
                                v-model="data[field]"
                                locale="pt-BR"
                                :max="9999999"
                                :minFractionDigits="2"
                                :maxFractionDigits="2"
                                :disabled="data['desativar']"
                                :class="{ 'p-invalid': campoInvalido.configuracaoIndicadores && !data[field] }"
                            />
                        </template>
                    </Column>

                    <Column field="dataBase" style="width: 10rem;">
                        <template #header>
                            Mês/Ano Base<span style="color:red;">&nbsp;*</span>
                        </template>

                        <template #body="{ data, field }">
                            <Calendar
                                v-model="data[field]"
                                dateFormat="MM/yy"
                                class="w-full"
                                view="month"
                                :class="{ 'p-invalid': campoInvalido.configuracaoIndicadores && !data[field] }"
                            />
                        </template>
                    </Column>

                    <Column field="fim" style="width: 10rem;">
                        <template #header>
                            Fim<span style="color:red;">&nbsp;*</span>
                        </template>

                        <template #body="{ data, field }">
                            <Calendar
                                v-model="data[field]"
                                dateFormat="dd/mm/yy"
                                class="w-full"
                                :class="{ 'p-invalid': campoInvalido.configuracaoIndicadores && !data[field] }"
                            />
                        </template>
                    </Column>
                </DataTable>
            </div>

            <div class="col-12 sm:col-12 md:col-12 lg:col-2 xl:col-2 mb-3">
                <div style="border: 1px solid #e4e4e4; height: 100%;">
                    <div class="col-12">
                        <div class="flex align-items-center justify-content-between" style="padding: 0.7rem 0 0.8rem 0;">
                            <strong style="font-size: 14px;">Fixação<span style="color:red;">&nbsp;*</span></strong>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="field">
                            <div class="flex align-items-center">
                                <RadioButton v-model="dados.tipoFixacao" id="antecipada" value="ANTECIPADA" :class="{ 'p-invalid': campoInvalido.tipoFixacao }" />
                                <label for="antecipada" class="ml-2">Antecipada</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="field">
                            <div class="flex align-items-center">
                                <RadioButton v-model="dados.tipoFixacao" id="embarque" value="EMBARQUE" :class="{ 'p-invalid': campoInvalido.tipoFixacao }" />
                                <label for="embarque" class="ml-2">Embarque</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="field">
                            <div class="flex align-items-center">
                                <RadioButton v-model="dados.tipoFixacao" id="antecipada-embarque" value="ANTECIPADA_EMBARQUE" :class="{ 'p-invalid': campoInvalido.tipoFixacao }" />
                                <label for="antecipada-embarque" class="ml-2">Antecipada + Embarque</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12">
                <div class="field">
                    <label for="detalhamento_regra">
                        <strong>Detalhamento da regra</strong>
                    </label>
                    <Textarea class="w-full" v-model="dados.detalhamentoRegra"></Textarea>
                </div>
            </div>
        </div>
    </Fieldset>
    <!-- MODALIDADE DE PREÇO A FIXAR FIM -->

    <!-- ADIANTAMENTO INICIO -->
    <Fieldset legend="Adiantamento" class="mt-3">
        <div class="flex align-items-center mb-2">
            <strong style="font-size: 14px;">Com adiantamento?</strong>
            <div class="flex align-items-center ml-3">
                <RadioButton v-model="dados.comAdiantamento" inputId="sim" value="SIM" @change="adiantamentoAction" :disabled="dados.adiantamentos != undefined && dados.adiantamentos.length > 0"/>
                <label for="sim" class="ml-2">Sim</label>
            </div>
            <div class="flex align-items-center ml-3">
                <RadioButton v-model="dados.comAdiantamento" inputId="nao" value="NAO" @change="adiantamentoAction" :disabled="dados.adiantamentos != undefined && dados.adiantamentos.length > 0"/>
                <label for="nao" class="ml-2">Não</label>
            </div>
        </div>

        <DataTable :value="dados.adiantamentos" showGridlines>
            <template #header>
                <div class="flex align-items-center justify-content-between">
                    <strong style="font-size: 14px;">Programação de adiantamento</strong>
                    <Button icon="pi pi-plus" aria-label="Incluir" :disabled="dados.comAdiantamento != 'SIM'" @click="adiantamentoDialog = true"></Button>
                </div>
            </template>

            <Column header="Data">
                <template #body="{ data }">
                    {{ formatDateCustom(data.data, "DD/MM/YYYY") }}
                </template>
            </Column>

            <Column header="Quantidade">
                <template #body="{ data }">
                    {{ formatDecimal(data.quantidade) }}
                </template>
            </Column>

            <Column header="Unidade">
                <template #body="{ data }">
                    {{ data.unidade?.descricao || "" }}
                </template>
            </Column>

            <Column header="Preço Unitário">
                <template #body="{ data }">
                    R$ {{ formatDecimal(data.precoUnitario) }}
                </template>
            </Column>

            <Column header="Valor">
                <template #body="{ data }">
                    R$ {{ formatDecimal(data.valorTotal) }}
                </template>
            </Column>

            <Column header="Ações" style="width:9rem;" class="centralizar-titulo-tabela">
                <template #body="{ index }">
                    <div class="flex align-items-center justify-content-center">
                        <Button
                            class="p-button-rounded p-button-info flex-none mr-2"
                            icon="pi pi-pencil"
                            aria-label="Editar"
                            @click="editarAdiantamento(index)"
                        ></Button>

                        <Button
                            class="p-button-rounded p-button-danger flex-none mr-2"
                            icon="pi pi-trash"
                            aria-label="Excluir"
                            @click="excluirAdiantamento(index)"
                        ></Button>
                    </div>
                </template>
            </Column>

            <template #footer>
                Quantidade Total: {{ formatDecimal(atiandamentoQuantidadeTotal) }} Ton. - Total Adiantamento: R$ {{ formatDecimal(valorAdiantamentoOut )}}
            </template>
        </DataTable>
    </Fieldset>
    <!-- ADIANTAMENTO FIM -->

    <Fieldset legend="Forma Pagamento" class="mt-3" >
        <div class="flex flex-wrap justify-content-start">
            <div class="flex align-items-center mr-3 mt-2 mb-2">
                <RadioButton v-model="dados.tipoFormaPagamento" id="no-dia" value="NO_DIA" @change="incluirParcela"/>
                <label for="no-dia" class="ml-2">
                    <strong>No Dia</strong>
                </label>
            </div>
            <div class="flex align-items-center mr-3 mt-2 mb-2">
                <RadioButton v-model="dados.tipoFormaPagamento" id="numero-dias-fixacao" value="NUMERO_DIAS_FIXACAO" @change="incluirParcela" :disabled="dados.modalidadePrecificacao == 'FIXO'"/>
                <label for="numero-dias-fixacao" class="ml-2">
                    <strong>Nº Dias da Fixação</strong>
                </label>
            </div>
            <div class="flex align-items-center mr-3 mt-2 mb-2">
                <RadioButton v-model="dados.tipoFormaPagamento" id="numero-dias-embarque" value="NUMERO_DIAS_EMBARQUE" @change="incluirParcela"/>
                <label for="numero-dias-embarque" class="ml-2">
                    <strong>Nº Dias do Embarque</strong>
                </label>
            </div>
            <div class="flex align-items-center mr-3 mt-2 mb-2">
                <RadioButton v-model="dados.tipoFormaPagamento" id="numero_dias_desembarque" value="NUMERO_DIAS_DESEMBARQUE" @change="incluirParcela"/>
                <label for="numero_dias_desembarque" class="ml-2">
                    <strong>Nº Dias do Desembarque</strong>
                </label>
            </div>
            <div class="flex align-items-center mr-3 mt-2 mb-2">
                <RadioButton v-model="dados.tipoFormaPagamento" id="parcelas" value="PARCELAS" @change="incluirParcela"/>
                <label for="parcelas" class="ml-2">
                    <strong>Parcelas</strong>
                </label>
            </div>

            <div class="flex align-items-center mr-4" v-if="dados.tipoFormaPagamento == 'NO_DIA'">
                <label class="mr-2" for="data_vencimento">Data Vencimento:<span style="color:red;">&nbsp;*</span></label>
                <Calendar v-model="dados.noDia" id="data_vencimento" dateFormat="dd/mm/yy" style="width: 100px;" @dateSelect="incluirParcela"/>
            </div>

            <div class="flex align-items-center" v-if="dados.tipoFormaPagamento.includes('NUMERO_DIAS')">
                <label class="mr-2" for="condicao_pagamento">Condição Pagamento:<span style="color:red;">&nbsp;*</span></label>
                <AutoComplete style="width:8rem"
                        placeholder="Selecione..."
                        dropdown
                        v-model="dados.descricao"
                        field="descricao"
                        id="condicao_pagamento"
                        :class="{ 'p-invalid': campoInvalido.condicaoPagamento }"
                        :suggestions="condicaoPagamentoFiltro"
                        @complete="buscarCondicaoPagamento"
                        @item-select="selecionarCondicaoPagamento($event)"
                    />

            </div>
        </div>

      <ParcelasForm v-if="dados.tipoFormaPagamento == 'PARCELAS'" :editavel="true" :dto="dados.parcelas" :ccv="dados" :totalAdiantamento="valorAdiantamentoOut" :campo-invalido="campoInvalido" />
      <ParcelasForm v-if="dados.tipoFormaPagamento == 'NO_DIA'" :editavel="false" :dto="dados.parcelas" :totalAdiantamento="valorAdiantamentoOut" :campo-invalido="campoInvalido" />

    </Fieldset>

    <!-- CONDIÇÃO DE PAGAMENTO INICIO -->
    <Fieldset legend="Modalidade de pagamento" class="mt-3">
        <div class="grid p-fluid formgrid">

            <div class="col-12 sm:col-6 md:col-3 lg:col-3 xl:col-3">
                <div class="field">
                    <label for="forma_pagamento">
                        <strong>Tipo<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <AutoComplete
                        placeholder="Selecione..."
                        dropdown
                        v-model="dados.formaPagamento"
                        field="descricao"
                        id="forma_pagamento"
                        class="w-full"
                        :class="{ 'p-invalid': campoInvalido.formaPagamento }"
                        :suggestions="formaPagamentoFiltro"
                        :forceSelection="true"
                        @complete="buscarFormaPagamento"
                    />
                </div>
            </div>

            <div class="col-12 sm:col-6 md:col-5 lg:col-5 xl:col-5">
                <div class="field">
                    <label for="banco">
                        <strong>Banco<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <DadosBancarioZoom
                        codigo="id"
                        descricao="banco"
                        :selecionado="bancoSelecinado"
                        :disabled="false"
                        :invalid="campoInvalido.banco"
                        :paginacao="bancoConsulta"
                        @pesquisar="pesquisarBanco"
                        @selecionado="selecionarBanco"
                    />
                </div>
            </div>

            <div class="col-12 sm:col-6 md:col-2 lg:col-2 xl:col-2">
                <div class="field">
                    <label for="agencia">
                        <strong>Agência</strong>
                    </label>
                    <InputText
                        v-model="dados.agencia"
                        id="agencia"
                        locale="pt-BR"
                        :disabled="true"
                    />
                </div>
            </div>

            <div class="col-12 sm:col-6 md:col-2 lg:col-2 xl:col-2">
                <div class="field">
                    <label for="conta">
                        <strong>Conta</strong>
                    </label>
                    <InputText
                        v-model="dados.conta"
                        id="conta"
                        locale="pt-BR"
                        :disabled="true"
                    />
                </div>
            </div>

            <div class="col-12 sm:col-6 md:col-3 lg:col-3 xl:col-3">
                <div class="field">
                    <label for="tipo_conta">
                        <strong>Tipo de conta</strong>
                    </label>
                    <InputText v-model="dados.tipoConta" id="tipo_conta" locale="pt-BR" class="w-full" :disabled="true" />
                </div>
            </div>

            <div class="col-12 sm:col-6 md:col-3 lg:col-3 xl:col-3">
                <div class="field">
                    <label for="cidade">
                        <strong>Cidade</strong>
                    </label>
                    <InputText v-model="dados.cidadePagamentoDescricao" id="cidade" locale="pt-BR" class="w-full" :disabled="true" />
                </div>
            </div>

            <div class="col-12 sm:col-6 md:col-3 lg:col-3 xl:col-3">
                <div class="field">
                    <label for="cnpj_vendedor">
                        <strong>CNPJ do vendedor</strong>
                    </label>
                    <InputMask
                        id="cnpj_vendedor"
                        mask="99.999.999/9999-99"
                        placeholder="99.999.999/9999-99"
                        v-model="dados.cnpjVendedor"
                        :disabled="true"
                    />
                </div>
            </div>
        </div>
    </Fieldset>
    <!-- CONDIÇÃO DE PAGAMENTO FIM -->

    <ModalParcela
        :visivel="parcelaDialog"
        :dados="parcela"
        :valorDolar="valorDolar"
        @fechar="fecharParcela"
        @salvar="salvarParcela"/>

    <ModalAdiantamento
        :visivel="adiantamentoDialog"
        :dados="adiantamento"
        :ccv="dados"
        :valorDolar="this.valorDolar"
        :modoEdicao="modoEdicao"
        @fechar="fecharAdiantamento"
        @salvar="salvarAdiantamento"/>
</template>

<script>
import MunicipioService from '../../../service/MunicipioService.js';
import ProdutorFiltro from '../ProdutorFiltro.vue';
import Formatacao from '../../../utilities/Formatacao';
import ModalAdiantamento from './ModalAdiantamento.vue';
import ModalParcela from './ModalParcela.vue';
import UnidadeMedidaService from './service/UnidadeMedidaService';
import CondicaoPagamentoService from './service/CondicaoPagamentoService';
import ComposicaoValorService  from '../../../service/ComposicaoValorService';
import ItemComposicaoValorForm from "@/components/cadastro/regrasComposicaoValores/ItemComposicaoValorForm.vue";
import ParcelasForm from "@/components/cadastro/contrato/ParcelasForm.vue";
import DadosBancarioService from '../../../service/DadosBancarioService';
import DadosBancarioZoom from '../../zoom/DadosBancarioZoom.vue';

export default {
    components: {ParcelasForm, ItemComposicaoValorForm, ProdutorFiltro, ModalAdiantamento, ModalParcela, DadosBancarioZoom },
    props: {
        dados: {
            type: Object,
            required: true
        },
        campoInvalido: {
            type: Object,
            required: true
        },
        valorDolar: {
          type: Number,
          required: true
        }
    },
    created() {
        this.setup();
        this.carregarCondicaoPagamento();
    },
    updated() {
        this.setup();
        if (this.dados.tipoFormaPagamento=="NO_DIA") {this.incluirParcela();}
        if (!!this.dados.banco) { this.bancoSelecinado = this.dados.banco; }
    },
    data() {
        return {
            unidadeFiltro: [],
            unidades: UnidadeMedidaService.lista(),

            condicaoPagamentoFiltro: [],
            condicoes: CondicaoPagamentoService.lista(),

            formaPagamentoFiltro: [],
            formaPagamentos: [
                { descricao: "PIX", codigo: "PIX" },
                { descricao: "TED", codigo: "TED" }
            ],

            bancoSelecinado: {},
            bancoConsulta: {},
            bancoFiltro: [],

            regraComposicaoFiltro: [],

            adiantamento: {},
            atiandamentoQuantidadeTotal: 0,
            adiantamentoUnidade: "Ton",
            adiantamentoValorTotal: 0,
            adiantamentoDialog: false,

            isValidCondicao: false,

            aFixarMarcarTudo: false,

            parcela: {},
            parcelaDialog: false,
            rollback: {},
            valorAdiantamentoOut: 0,
            modoEdicao: false,
            valorDolar: this.valorDolar
        }
    },

    methods: {
        setup() {
            if(!this.dados.modalidadePrecificacao) {
                this.dados.modalidadePrecificacao = "FIXO";
            }

            if(!this.dados.adiantamentos) {
                this.dados.adiantamentos = [];
            }

            if(!this.dados.parcelas) {
                this.dados.parcelas = [];
            }

            if(!this.dados.retiradas) {
                this.dados.retiradas = [];
            }

            if(!this.dados.comAdiantamento) {
                this.dados.comAdiantamento = "SIM";
            }

            if(!this.dados.condicaoPagamento) {
                this.isValidCondicao = true;
            }

            if(!this.dados.tipoFormaPagamento) {
                this.dados.tipoFormaPagamento = "NO_DIA";
                this.dados.noDia = new Date();
            }

            this.calcularQuantidadeTotalAdiantamento();
            this.incluirParcela();
        },

        salvaTemp(){
          if(!!this.rollback.tipoFixacao) {return;}
          this.rollback["composicaoValor"] = this.dados.composicaoValor;
          this.rollback["regras"] = this.dados.regras;
          this.rollback["precoUnitario"] = this.dados.precoUnitario;
          this.rollback["valorFaturamentoSimbolico"] = this.dados.valorFaturamentoSimbolico;
          this.rollback["tipoFixacao"] = this.dados.tipoFixacao;
        },

        leTemp(){
          this.dados.tipoFixacao = this.rollback.tipoFixacao;
          this.dados.composicaoValor = this.rollback.composicaoValor;
          this.dados.regras = this.rollback.regras;
          this.dados.precoUnitario = this.rollback.precoUnitario;
          this.dados.valorFaturamentoSimbolico = this.rollback.valorFaturamentoSimbolico;
        },

        buscarUnidade(event) {
            if (!event.query.trim().length) {
                this.unidadeFiltro = [...this.unidades];
            } else {
                this.unidadeFiltro = this.unidades.filter((item) => {
                    return item.descricao.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }
        },

        selecionarCondicaoPagamento(sel) {
          this.dados.condicaoPagamento=sel.value;
        },

        buscarCondicaoPagamento(event) {
            if (!event.query.trim().length) {
                this.condicaoPagamentoFiltro = [...this.condicoes];
            } else {
                this.condicaoPagamentoFiltro = this.condicoes.filter((item) => {
                    return item.descricao.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }
        },

        carregarCondicaoPagamento() {
            CondicaoPagamentoService.lista()
                .then(({ data }) => {
                    this.condicoes = data;
                })
        },

        buscarRegrasComposicao(event) {
            const filtro = !event.query.trim().length ? undefined : event.query.toLowerCase();
            ComposicaoValorService.buscarPorIdOuDescricao(filtro,this.dados.tipoFixacao == 'FIXO_DOLAR')
                .then(({ data }) => {
                  this.regraComposicaoFiltro = data;
                  if (data.length > 0){
                    this.dados.composicaoValor = data[0];
                    this.preencherTabelaRegrasComConfiguracaoIndicadores();
                  }
                })
                .catch(error => {
                    this.$toast.add({
                            severity:'error',
                            summary: 'Falha ao carregar lista de regras de composição.',
                            detail: error,
                            life: 15000
                        });
                });
        },

        preencherTabelaRegrasComConfiguracaoIndicadores() {
            const idConposicaoValor = this.dados.composicaoValor.id;
            ComposicaoValorService.buscarIndicadoresPrincipais(idConposicaoValor)
                .then(({ data }) => {
                    this.dados.regras = data.map(item => {
                        const novoItem = {};
                        novoItem["regra"] = item.indicadorValor.sigla;
                        novoItem["moeda"] = item.indicadorValor.tipo;
                        novoItem["moedaDescricao"] = item.indicadorValor.tipoDescricao;
                        novoItem["valorPadrao"] = item.valorPadrao;
                        novoItem["itemComposicaoValorId"] = item.id;
                        novoItem["contratoCompraVendaId"] = this.dados.id;
                        novoItem["desativar"] = !item.alteraValorPadraoCCV;
                        return novoItem;
                    });
                });
        },

        sugerirFatorConversao(){
            if (this.dados.unidadePrecoFixo['codigo'] == 'KILO') this.dados.fatorConversao = 1;
            if (this.dados.unidadePrecoFixo['codigo'] == 'SACA') this.dados.fatorConversao = 60;
            if (this.dados.unidadePrecoFixo['codigo'] == 'TONELADA') this.dados.fatorConversao = 1000;
            this.incluirParcela();
        },

        buscarFormaPagamento(event) {
            if (!event.query.trim().length) {
                this.formaPagamentoFiltro = [...this.formaPagamentos];
            } else {
                this.formaPagamentoFiltro = this.formaPagamentos.filter((item) => {
                    return item.descricao.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }
        },

        buscarBanco(event) {
            if (!event.query.trim().length) {
                this.bancoFiltro = [...this.bancos];
            } else {
                this.bancoFiltro = this.bancos.filter((item) => {
                    return item.nome.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }
        },

        /* FUNCIONALIDADES TABELA ADIANTAMENTO - INICIO */
        adiantamentoAction() {
            if(!!this.dados.dados.comAdiantamento) {
            }
            else {
                this.dados.adiantamentos = [];
            }
        },

        salvarAdiantamento(adiantamento) {
            const indice = adiantamento.indice;

            if(indice >= 0) {
                this.dados.adiantamentos[indice] = adiantamento;
            }
            else {
                this.dados.adiantamentos.push(adiantamento);
            }

            this.adiantamento = {};
            this.calcularQuantidadeTotalAdiantamento();
            this.modoEdicao = false;
        },

        fecharAdiantamento() {
            this.adiantamentoDialog = false;
            this.adiantamento = {};
        },

        editarAdiantamento(indice) {
            this.modoEdicao = true;
            const adiantamento = Object.assign({}, this.dados.adiantamentos[indice]);
            adiantamento["indice"] = indice;

            this.adiantamentoDialog = true;
            this.adiantamento = adiantamento;
        },

        excluirAdiantamento(indice) {
            this.dados.adiantamentos.splice(indice,1);
            this.calcularQuantidadeTotalAdiantamento();
            this.incluirParcela();
        },

        calcularQuantidadeTotalAdiantamento() {
            let adiantamentos = [];
            this.adiantamentoValorTotal = 0;
            this.atiandamentoQuantidadeTotal = 0;

          if(this.dados.adiantamentos !== undefined && this.dados.adiantamentos !== null && this.dados.adiantamentos.length > 0) {
                adiantamentos = this.dados.adiantamentos;
            }

            if(!!adiantamentos.length) {
              let unidadeCCV = UnidadeMedidaService.buscarPorCodigo(this.dados.unidade.codigo);
              let fatorCCV = unidadeCCV.fatorConversao;
              let unidadeAdiantamento = UnidadeMedidaService.buscarPorCodigo(adiantamentos[0].unidade.codigo);
              let fatorAdiantamento = unidadeAdiantamento.fatorConversao;

              let somaQtd = 0;
              for (let a of adiantamentos){
                let un = UnidadeMedidaService.buscarPorCodigo(a.unidade.codigo);
                let fa = un.fatorConversao;
                let qt = a.quantidade / 1000 * fa;
                somaQtd = somaQtd + qt;
              }
              let qtd1 = somaQtd;
              this.atiandamentoQuantidadeTotal = qtd1 ;
              this.adiantamentoUnidade = unidadeAdiantamento.sigla;

              let qtd2 = adiantamentos[0].quantidade * adiantamentos[0].precoUnitario;
              this.adiantamentoValorTotal = qtd2 / fatorCCV * fatorAdiantamento ;

            }

        },
        /* FUNCIONALIDADES TABELA ADIANTAMENTO - FIM */

        formatDateCustom(date, format) {
            return Formatacao.formatDateCustom(date, format);
        },

        formatDecimal(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
            return Formatacao.formatDecimal(value, minimumFractionDigits, maximumFractionDigits);
        },

        aFixarMarcarTudoAction() {
            this.dados.regras = this.dados.regras.map((item) => {
                item["ativo"] = this.aFixarMarcarTudo;
                return item;
            });
        },

          incluirParcela() {
          if (!this.dados.quantidade) {return;}

          if (this.dados.tipoFormaPagamento!="NO_DIA") {
            this.buscaValorAdiantamento();
            return;
          } else {
            this.dados.parcelas = [];
            let valorAdiantamento = 0;

            valorAdiantamento = this.buscaValorAdiantamento();

            const linha = {
                data: this.dados.noDia,
                percentual: 100,
                valorTotal: this.calculaValorTotal() - valorAdiantamento
              }
              this.dados.parcelas.push(linha);
          }
        },

        calculaValorTotal(){
          return (this.dados.modalidadePrecificacao == 'A_FIXAR' ?
              this.dados.quantidade * this.dados.valorFaturamentoSimbolico/60 * this.dados.unidade.fatorConversao :
              this.dados.modalidadePrecificacao == 'FIXO' ?
                  this.dados.quantidade * this.dados.precoUnitario / this.dados.fatorConversao * this.dados.unidade.fatorConversao :
                  this.dados.quantidade * this.dados.precoUnitario * this.valorDolar * this.dados.unidade.fatorConversao / (!this.dados.unidadePrecoFixo ?
                      1 : this.dados.unidadePrecoFixo.fatorConversao));
        },

        buscaValorAdiantamento(){
          let valorAdiantamento = 0;

          if(this.dados.adiantamentos.length != 0){
              let vl = 0;
              for(let adiantamento of this.dados.adiantamentos){
                vl = vl + adiantamento.valorTotal;
              }
              valorAdiantamento = vl;
          }

          this.$data.valorAdiantamentoOut = valorAdiantamento;
          return valorAdiantamento;
        },

        editarParcela(indice) {
            const parcela = Object.assign({}, this.dados.parcelas[indice]);
            parcela["indice"] = indice;

            this.parcelaDialog = true;
            this.parcela = parcela;
        },

        excluirParcela(indice) {
            this.dados.parcelas.splice(indice, 1);
        },

        fecharParcela() {
            this.parcelaDialog = false;
            this.dado.parcela = {};
        },

        salvarParcela(parcela) {
            const indice = parcela.indice;

            if(indice >= 0) {
                this.dados.parcelas[indice] = parcela;
            }
            else {
                this.dados.parcelas.push(parcela);
            }

            this.parcela = {};
        },

        verifica(evento) {
            this.salvaTemp();
            if(evento==2) {
                this.dados.tipoFixacao = "FIXO_DOLAR";
                let ev = {query: ""};
                this.buscarRegrasComposicao(ev);
            } else {
                this.leTemp();
                this.dados.tipoFixacao = "";
                let ev = {query: ""};
                this.buscarRegrasComposicao(ev);
            }
            this.incluirParcela();
        },

        cancelar() {
            if(!this.editingRows && this.editingRows.length <= 0) return;
            this.editingRows = [];
            this.adicionarLinha();
        },

        adicionarLinha() {
            const indice = this.dados.parcelas.findIndex(item => { return !item.data || !item.percentual || !item.valorTotal });
            if(indice < 0) this.dados.parcelas.push({});
        },

        pesquisarBanco(filtros) {
            DadosBancarioService.buscarComPaginacao(filtros)
                .then(({ data }) => {
                    if(data) {
                        this.bancoConsulta = data;
                    }
                })
                .catch(error => {
                    this.bancoConsulta = {}

                    const toast = {
                        severity: 'error',
                        summary: 'Falha ao carregar os dados bancários.',
                        detail: "Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!", 
                        life: 7500
                    }

                    if(!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;
                        toast["detail"] = message;
                    }

                    this.$toast.add(toast);
                });
        },

        selecionarBanco(codigo) {
            if(codigo == undefined || codigo == null || codigo == "") {
                this.bancoSelecinado = {};
                this.dados.banco = {};
                this.dados.agencia = null;
                this.dados.conta = null;
                this.dados.tipoConta = null;
                this.dados.cnpjVendedor = null;
                this.dados.cidadePagamento = null;
                this.dados.cidadePagamentoDescricao = null;
                return;
            }

            DadosBancarioService.buscarPorId(codigo)
                .then(({ data }) => {
                    this.bancoSelecinado = data;
                    this.dados.banco = data;
                    
                    this.dados.agencia = data.agencia;
                    this.dados.conta = data.conta;
                    this.dados.tipoConta = !!data.tipoConta ? data.tipoConta.descricao : null;
                    this.dados.cnpjVendedor = data.cnpj;

                    if(!!data.municipio) {
                        const cidadePagamento = data.municipio;
                        this.dados.cidadePagamento = cidadePagamento
                        this.dados.cidadePagamentoDescricao = `${ cidadePagamento["nome"] } - ${ cidadePagamento["uf"] }`;
                    }

                })
                .catch(error => {
                    this.dados.banco = {};

                    this.dados.agencia = null;
                    this.dados.conta = null;
                    this.dados.tipoConta = null;
                    this.dados.cnpjVendedor = null;
                    this.dados.cidadePagamento = null;
                    this.dados.cidadePagamentoDescricao = null;

                    const toast = {
                        severity: 'error',
                        summary: `Falha ao buscar os dados bancário.`,
                        detail: "Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!", 
                        life: 7500
                    }

                    if(!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;

                        if(!!message) {
                            toast["detail"] = message;
                        }
                    }

                    this.$toast.add(toast);
                });
        },

        convertDateToString(data, formato) {
            return Formatacao.convertDateToString(data, formato);
        }
    }
}
</script>

<style>
    .centralizar-titulo-tabela {
        text-align: center !important;
    }

    .centralizar-titulo-tabela .p-column-header-content {
        display: inline;
    }
</style>
<template>
    <Dialog v-model:visible="visivel" :style="{width: '600px', margin: '10%'}" :modal="true" @hide="fechar()">
        <template #header>
            <div class="flex align-items-center mt-2">
                <h4 style="margin:0;">Adiantamento</h4>
            </div> 
        </template>

        <Message v-if="mensagem.ativo" :severity="mensagem.gravidade" @close="mensagem.ativo = false">{{ mensagem.conteudo }}</Message>

        <div class="grid p-fluid formgrid">
            <div class="col-4 sm:col-4 md:col-4 lg:col-4 xl:col-4">
                <div class="field">
                    <label for="data">
                        <strong>Data<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <Calendar
                        class="w-full"
                        :class="{ 'p-invalid': isValidData }"
                        v-model="dados.data"
                        dateFormat="dd/mm/yy"
                        @focus="limparMensagem"
                    />
                </div>
            </div>

            <div class="col-4 sm:col-4 md:col-4 lg:col-4 xl:col-4">
                <div class="field">
                    <label for="quantidade">
                        <strong>Quantidade<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <InputNumber
                        class="w-full"
                        :class="{ 'p-invalid': isValidQuantidade }"
                        v-model="dados.quantidade"
                        id="quantidade"
                        locale="pt-BR"
                        :max="9999999"
                        :minFractionDigits="2"
                        :maxFractionDigits="2"
                        @blur="atualizarPrecoTotal($event)"
                    />
                </div>
            </div>

            <div class="col-4 sm:col-4 md:col-4 lg:col-4 xl:col-4">
                <div class="field">
                    <label for="unidade">
                        <strong>Unidade<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <AutoComplete
                        placeholder="Selecione..." 
                        dropdown
                        v-model="dados.unidade"
                        field="descricao"
                        id="unidade"
                        class="w-full"
                        :class="{ 'p-invalid': isValidUnidade }"
                        :suggestions="unidadeFiltro"
                        @complete="buscarUnidade"
                    />
                </div>
            </div>

            <div class="col-4 sm:col-4 md:col-4 lg:col-4 xl:col-4">
                <div class="field">
                    <label for="preco_unitario">
                        <strong>Preço unitário<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <InputNumber
                        class="w-full"
                        :class="{ 'p-invalid': isValidPrecoUnitario }"
                        v-model="dados.precoUnitario"
                        id="preco_unitario"
                        locale="pt-BR"
                        :max="9999999"
                        :minFractionDigits="2"
                        :maxFractionDigits="2"
                        @blur="atualizarPrecoTotal($event)"
                    />
                </div>
            </div>

            <div class="col-4 sm:col-4 md:col-4 lg:col-4 xl:col-4">
                <div class="field">
                    <label for="valor_total">
                        <strong>Valor total</strong>
                    </label>
                    <InputNumber 
                        v-model="dados.valorTotal"
                        id="valor_total"
                        locale="pt-BR"
                        :minFractionDigits="2"
                        :maxFractionDigits="2"
                        :disabled="true"
                    />
                </div>
            </div>
        </div>

        <template #footer>
            <div class="flex justify-content-center">
                <Button
                    icon="pi pi-save"
                    label="Salvar"
                    :disabled="doubleClick"
                    class="p-button-info ml-1"
                    @click="salvar()"
                ></Button>

                <Button disabled="!isValidValorTotal"
                    icon="pi pi-times"
                    label="Cancelar"
                    class="p-button-danger"
                    @click="fechar()"
                ></Button>
            </div>
        </template>
    </Dialog>
</template>

<script>
import UnidadeMedidaService from './service/UnidadeMedidaService';
export default {
    name: "ModalAdiantamento",
    emits: ["fechar"],
    props: {
        visivel: {
            type: Boolean,
            required: true
        },
        dados: {
            type: Object,
            required: true
        },
        ccv: {
            type: Object,
            required: true
        },
        modoEdicao: {
            type: Boolean,
            required: true
        },
        valorDolar: {
          type: Number,
          required: true
        }
    },
    data() {
        return {
            unidadeFiltro: [],
            unidades: UnidadeMedidaService.lista(),

            mensagem: { ativo: false, gravidade: 'info', conteudo: '' },

            isValidData: false,
            isValidQuantidade: false,
            isValidUnidade: false,
            isValidPrecoUnitario: false,
            isValidValorTotal: false

        }
    },
    methods: {
        isCamposObrigatoriosPreenchidos() {
            let isPreenchido = true;

            if(!this.dados.data) {
                this.isValidData = true;
                isPreenchido = false;
            }
            
            if(!this.dados.quantidade) {
                this.isValidQuantidade = true;
                isPreenchido = false;
            }
            
            if(!this.dados.unidade) {
                this.isValidUnidade = true;
                isPreenchido = false;
            }

            if(!this.dados.precoUnitario) {
                this.isValidPrecoUnitario = true;
                isPreenchido = false;
            }

          if(this.buscaValorAdiantamento()>this.calculaValorTotal()){
              this.isValidValorTotal = false;
              isPreenchido = true;
          } else {
              this.isValidValorTotal = true;
              isPreenchido = true;
          }

            if(!isPreenchido) {
                this.mensagem = {
                    ativo: true,
                    gravidade: 'warn',
                    conteudo: 'Por favor, preencher os campos obrigatórios!'
                }
            }

            if(!this.isValidValorTotal) {
              isPreenchido = false;
              this.mensagem = {
                ativo: true,
                gravidade: 'warn',
                conteudo: 'O Valor Total do adiantamento não pode ser maior do que o Valor do Contrato!'
              }
            }

            return isPreenchido;
        },

        limpar() {
            this.isValidData = false;
            this.isValidQuantidade = false;
            this.isValidUnidade = false;
            this.isValidPrecoUnitario = false;
            this.isValidValorTotal = false;
        },

        limparMensagem() {
            this.mensagem = { ativo: false };
        },

        salvar() {
            if(!this.isCamposObrigatoriosPreenchidos()) return;

            const adiantamento = Object.assign({}, this.dados);
            this.$emit("salvar", adiantamento);
            
            if(adiantamento.indice !== undefined && adiantamento.indice !== null) {
                this.fechar(adiantamento);
            }
            else {
                this.limparMensagem();
                this.limpar();
                this.fechar();
            }
        },

        fechar(adiantamento) {
            this.$emit("fechar", adiantamento);
            this.limpar();
            this.limparMensagem();
        },

        calculaValorTotal(){
          return (this.ccv.modalidadePrecificacao == 'A_FIXAR' ?
              this.ccv.quantidade * this.ccv.valorFaturamentoSimbolico/60 * this.ccv.unidade.fatorConversao :
              this.ccv.modalidadePrecificacao == 'FIXO' ?
                  this.ccv.quantidade * this.ccv.precoUnitario / this.ccv.fatorConversao * this.ccv.unidade.fatorConversao :
                  this.ccv.quantidade * this.ccv.precoUnitario * this.valorDolar * this.ccv.unidade.fatorConversao / (!this.ccv.unidadePrecoFixo ?
                      1 : this.ccv.unidadePrecoFixo.fatorConversao));
        },

        buscaValorAdiantamento(){
          let valorAdiantamento = 0;

          if (!!this.modoEdicao){
            this.ccv.adiantamentos[this.dados.indice] = this.dados;
          }

          let vl = 0;
          if(this.ccv.adiantamentos.length != 0){
            for(let adiantamento of this.ccv.adiantamentos){
              vl = vl + adiantamento.valorTotal;
            }
          }
          valorAdiantamento = vl ;
          return valorAdiantamento;
        },

        atualizarPrecoTotal(event){ 
            let quantidade = !!this.dados.quantidade ? this.dados.quantidade : 0;
            let precoUnitario = !!this.dados.precoUnitario ? this.dados.precoUnitario : 0;

            if(!!event) {
                const { value, id } = event.target;

                if(!!value) {
                    let valor = value.replace(/\./g, "");
                    valor = valor.replace(/\,/, ".");
                    valor = parseFloat(valor);

                    if(id == "quantidade") {
                        quantidade = valor;
                    }
                    else if(id == "preco_unitario") {
                        precoUnitario = valor;
                    }
                }
            }

            this.dados.valorTotal = quantidade * precoUnitario;
        },

        buscarUnidade(event) {
            if (!event.query.trim().length) {
                this.unidadeFiltro = [...this.unidades];
            } else {
                this.unidadeFiltro = this.unidades.filter((item) => {
                    return item.descricao.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }
        }
    }
}
</script>
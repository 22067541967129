<template>
    <div class="card">
        <div class="flex justify-content-between">
            <div class="flex align-items-center">
                <img src="../../../assets/img/contrato.png" style="width: 2.3rem; margin-right: 0.5rem;"/>
                <h3 style="margin:0px 5px;">{{ id ? 'Editar' : 'Cadastrar'}} Contrato de Compra e Venda</h3>
            </div>  
        </div>

        <hr />

        <div class="grid p-fluid formgrid mt-3">
            <div class="col-12 sm:col-4 md:col-3 lg:col-3 xl:col-2">
                <div class="field">
                    <label for="contrato_interno">
                        <strong>Nro. Contrato Interno</strong>
                    </label>
                    <InputText v-model="dados.contratoInternoId" id="contrato_interno" :disabled="true" />
                </div>
            </div>

            <div class="col-12 sm:col-8 md:col-9 lg:col-9 xl:col-4">
                <div class="field">
                    <label for="corretora">
                        <strong>Corretora<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <CorretoraZoom
                        codigo="codigoCliente"
                        descricao="nome"
                        :selecionado="dados.corretora"
                        :disabled="false"
                        :invalid="campoInvalido.corretora"
                        :paginacao="corretoraConsulta"
                        @pesquisar="pesquisarCorretora"
                        @selecionado="selecionarCorretora"
                    />
                </div>
            </div>

            <div class="col-12 sm:col-4 md:col-3 lg:col-3 xl:col-2">
                <div class="field">
                    <label for="contrato_corretora">
                        <strong>Contrato Corretora<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <InputText 
                        v-model="dados.contratoCorretora"
                        id="contrato_corretora"
                        :class="{ 'p-invalid': campoInvalido.contratoCorretora }"
                    />
                </div>
            </div>

            <div class="col-12 sm:col-4 md:col-3 lg:col-3 xl:col-2">
                <div class="field">
                    <label for="data_contrato">
                        <strong>Data Contrato Corretora<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <Calendar
                        v-model="dados.dataContrato"
                        id="data_contrato"
                        dateFormat="dd/mm/yy"
                        class="w-full"
                        :class="{ 'p-invalid': campoInvalido.dataContrato }"
                    />
                </div>
            </div>

            <div class="col-12 sm:col-4 md:col-3 lg:col-3 xl:col-2">
                <div class="field">
                    <label for="contratoJuridico">
                        <strong>Contrato Jurídico</strong>
                    </label>
                    <InputText v-model="dados.contratoJuridico" id="contratoJuridico" />
                </div>
            </div>
        </div>

        <TabView class="mt-3">
            <TabPanel>
                <template #header>
                    <i class="pi pi-file mr-2" style="font-size: 1rem;"></i>
                    <span>Dados Gerais</span>
                </template>

                <ContratoDadosGerais 
                    :dados="dados"
                    :campoInvalido="campoInvalido"
                />
            </TabPanel>

            <TabPanel>
                <template #header>
                    <img src="../../../assets/img/caminhao.png" style="width: 1.4rem; margin-right: 0.5rem;"/>
                    <span>Logistica</span>
                </template>

                <ContratoEntrega 
                    :dados="dados" 
                    :regionais="regionais"
                    :estabelecimentos="estabelecimentos"
                    :campoInvalido="campoInvalido"/>
            </TabPanel>

            <TabPanel>
                <template #header>
                    <i class="pi pi-chart-line mr-2" style="font-size: 1rem;"></i>
                    <span>Precificação</span>
                </template>

                <ContratoPrecificacao
                    :valorDolar="valorDolar"
                    :dados="dados"
                    :rollback="rollback"
                    :campoInvalido="campoInvalido"
                />
            </TabPanel>

            <TabPanel>
                <template #header>
                    <i class="pi pi-info-circle mr-2" style="font-size: 1rem;"></i>
                    <span>Corretora</span>
                </template>

                <ContratoCorretora 
                    :dados="dados"
                />
            </TabPanel>
        </TabView>

        <hr />

        <div class="flex">
            <Button
                icon="pi pi-save"
                label="Salvar"
                class="p-button-info"
                @click="salvar"
            ></Button>

            <Button
                icon="pi pi-times"
                label="Cancelar"
                class="p-button-danger ml-2"
                @click="voltar"
            ></Button>
        </div>
    </div>
</template>

<script>
import ContratoDadosGerais from './ContratoDadosGerais.vue';
import ContratoEntrega from './ContratoEntrega.vue';
import ContratoPrecificacao from './ContratoPrecificacao.vue';
import ContratoCorretora from './ContratoCorretora.vue';
import CorretoraZoom from '../../zoom/CorretoraZoom.vue';
import CorretoraService from '../../../service/CorretoraService';
import Formatacao from '../../../utilities/Formatacao';
import UnidadeMedidaService from './service/UnidadeMedidaService';
import StorageService from '../../../service/StorageService';
import EstabelecimentoService from '../../../service/EstabelecimentoService';
import ContratoCompraVendaService from '../../../service/ContratoCompraVendaService';
import CotacaoDolarService from "@/service/CotacaoDolarService";

export default {
    components: { ContratoDadosGerais, ContratoEntrega, ContratoPrecificacao, ContratoCorretora, CorretoraZoom },
    
    props: {
        id: {
            type: Number,
            required: false
        },
        clonar: {
            type: Boolean,
            required: false
        }
    },
    
    data() {
        return {
            dados: {
                naturezaOperacao: {},
                corretora: {},
                destino: null,
            },
            regionais: [],
            estabelecimentos: [],
            campoInvalido: {},
            corretoraConsulta: {},
            valorDolar: 0.00
        }
    },

    methods: {
        voltar() {
            this.$router.push(`/contratos`);
        },

        obterValorDolar(){
          let data = Formatacao.convertDateToString(Formatacao.subtrairDias(new Date(), 1),"YYYY-MM-DD");

          CotacaoDolarService.buscarPorData(data)
              .then(({ data }) => {
                if(!!data){
                  this.valorDolar = data.valorDolar;
                }
              });
        },

        validarCampos() {
            let campos = [];

            if(!this.dados.corretora.codigoCliente) {
                campos.push("Corretora");
                this.campoInvalido.corretora = true;
            }
            else {
                this.campoInvalido.corretora = false;
            }

            if(!this.dados.contratoCorretora) {
              campos.push("Nro Contrato Corretora");
              this.campoInvalido.contratoCorretora = true;
            }
            else {
              this.campoInvalido.contratoCorretora = false;
            }

            if(!this.dados.dataContrato) {
                campos.push("Data contrato");
                this.campoInvalido.dataContrato = true;
            }
            else {
                this.campoInvalido.dataContrato = false;
            }
                      
            /* DADOS GERAIS */
            if(!this.dados.grupoProduto) {
                campos.push("Grupo Produto");
                this.campoInvalido.grupoProduto = true;
            }
            else {
                this.campoInvalido.grupoProduto = false;
            }

            if(!this.dados.quantidade) {
                campos.push("Quantidade");
                this.campoInvalido.quantidade = true;
            }
            else {
                this.campoInvalido.quantidade = false;
            }

            if(!this.dados.unidade) {
                campos.push("Unidade");
                this.campoInvalido.unidade = true;
            }
            else {
                this.campoInvalido.unidade = false;
            }

            if(!this.dados.safra) {
                campos.push("Safra");
                this.campoInvalido.safra = true;
            }
            else {
                this.campoInvalido.safra = false;
            }

            if(!this.dados.tipoTributacao) {
                campos.push("Tipo Tributação");
                this.campoInvalido.tipoTributacao = true;
            }
            else {
                this.campoInvalido.tipoTributacao = false;
            }

            if(!!this.dados.possuiUmidade && !this.dados.limiteUmidade) {
                campos.push("Limite de Umidade (%)");
                this.campoInvalido.limiteUmidade = true;
            }
            else {
                this.campoInvalido.limiteUmidade = false;
            }

            if(!!this.dados.possuiImpureza && !this.dados.limiteImpureza) {
                campos.push("Limite de Impureza (%)");
                this.campoInvalido.limiteImpureza = true;
            }
            else {
                this.campoInvalido.limiteImpureza = false;
            }

            if(!!this.dados.possuiChuvadoAvariado && !this.dados.limiteGraoAvariado) {
                campos.push("Limite Grão Avariado (%)");
                this.campoInvalido.limiteGraoAvariado = true;
            }
            else {
                this.campoInvalido.limiteGraoAvariado = false;
            }

            if(!!this.dados.possuiTbm && !this.dados.limiteTbm) {
                campos.push("Limite TBM (%)");
                this.campoInvalido.limiteTbm = true;
            }
            else {
                this.campoInvalido.limiteTbm = false;
            }

            if(!!this.dados.possuiPh && !this.dados.limitePh) {
                campos.push("Limite PH (%)");
                this.campoInvalido.limitePh = true;
            }
            else {
                this.campoInvalido.limitePh = false;
            }

            if(!!this.dados.possuiBandinha && !this.dados.limiteBandinha) {
                campos.push("Limite Bandinha (%)");
                this.campoInvalido.limiteBandinha = true;
            }
            else {
                this.campoInvalido.limiteBandinha = false;
            }

            if(!this.dados.vendedor.codProdutor) {
                campos.push("Vendedor");
                this.campoInvalido.vendedor = true;
            }
            else {
                this.campoInvalido.vendedor = false;
            }

            if(!this.dados.comprador.codProdutor) {
                campos.push("Comprador");
                this.campoInvalido.comprador = true;
            }
            else {
                this.campoInvalido.comprador = false;
            }

            if(!this.dados.destinoMercadoria) {
                campos.push("Destino Mercadoria");
                this.campoInvalido.destinoMercadoria = true;
            }else {
                this.campoInvalido.destinoMercadoria = false;
            }

            if(!this.dados.naturezaOperacao || !this.dados.naturezaOperacao.codigo) {
                campos.push("Natureza Operação");
                this.campoInvalido.naturezaOperacao = true;
            }else {
                this.campoInvalido.naturezaOperacao = false;
            }
    
            if(this.dados.modalidadeFrete == "CIF" && !this.dados.localOrigemEntrega) {
                campos.push("Regional de Origem Preferencial");
                this.campoInvalido.localOrigemEntrega = true;
            }
            else {
                this.campoInvalido.localOrigemEntrega = false;
            }

            if(!this.dados.destino) {
                campos.push("Destino de entrega");
                this.campoInvalido.destino = true;
            }
            else {
                this.campoInvalido.destino = false;
            }

            if(!this.dados.inicioEmbarque) {
              campos.push("Inicio Embarque");
              this.campoInvalido.inicioEmbarque = true;
            }
            else {
              this.campoInvalido.inicioEmbarque = false;
            }

            if(!this.dados.fimEmbarque) {
              campos.push("Fim Embarque");
              this.campoInvalido.fimEmbarque = true;
            }
            else {
              this.campoInvalido.fimEmbarque = false;
            }

            /* PRECIFICAÇÃO */
            if(this.dados.modalidadePrecificacao === "FIXO") {
                if(!this.dados.precoUnitario) {
                    campos.push("Preço Unitário");
                    this.campoInvalido.precoUnitario = true;
                }
                else {
                    this.campoInvalido.precoUnitario = false;
                }

                if(!this.dados.unidadePrecoFixo) {
                    campos.push("Unidade");
                    this.campoInvalido.unidadePrecoFixo = true;
                }
                else {
                    this.campoInvalido.unidadePrecoFixo = false;
                }

                if(!this.dados.fatorConversao) {
                    campos.push("Fator de conversão");
                    this.campoInvalido.fatorConversao = true;
                }
                else {
                    this.campoInvalido.fatorConversao = false;
                }
            }
            else if(this.dados.modalidadePrecificacao === "A_FIXAR") {
                if(!this.dados.tipoFixacao) {
                    campos.push("Tipo Fixação");
                    this.campoInvalido.tipoFixacao = true;
                }
                else {
                    this.campoInvalido.tipoFixacao = false;
                }

                if(!this.dados.composicaoValor) {
                    campos.push("Regras");
                    this.campoInvalido.composicaoValor = true;
                }
                else {
                    this.campoInvalido.composicaoValor = false;

                    const regrasNaoPreenchida = this.dados.regras.filter(item => {                        
                        let valorPadrao = false;
                        if(!item.desativar) valorPadrao = !item.valorPadrao;
                        return valorPadrao || !item.dataBase || !item.fim;
                    })
                    
                    if(regrasNaoPreenchida.length > 0) {
                        campos.push("Configuração de Indicadores");
                        this.campoInvalido.configuracaoIndicadores = true;
                    }
                    else {
                        this.campoInvalido.configuracaoIndicadores = false;
                    }
                }

                if(!this.dados.valorFaturamentoSimbolico) {
                    campos.push("Valor Faturamento Simbólico");
                    this.campoInvalido.valorFaturamentoSimbolico = true;
                }
                else {
                    this.campoInvalido.valorFaturamentoSimbolico = false;
                }

                if(!this.dados.dataFimFixacao) {
                    campos.push("Data Fim Fixação");
                    this.campoInvalido.dataFimFixacao = true;
                }
                else {
                    this.campoInvalido.dataFimFixacao = false;
                }
            } else if(this.dados.modalidadePrecificacao === "FIXO_DOLAR") {
              if(!this.dados.tipoFixacao) {
                campos.push("Tipo Fixação");
                this.campoInvalido.tipoFixacao = true;
              }
              else {
                this.campoInvalido.tipoFixacao = false;
              }

              if(!this.dados.composicaoValor) {
                campos.push("Regras");
                this.campoInvalido.composicaoValor = true;
              }
              else {
                this.campoInvalido.composicaoValor = false;

                const regrasNaoPreenchida = this.dados.regras.filter(item => {
                  let valorPadrao = false;
                  if(!item.desativar) valorPadrao = !item.valorPadrao;
                  return valorPadrao || !item.dataBase || !item.fim;
                })

                if(regrasNaoPreenchida.length > 0) {
                  campos.push("Configuração de Indicadores");
                  this.campoInvalido.configuracaoIndicadores = true;
                }
                else {
                  this.campoInvalido.configuracaoIndicadores = false;
                }
              }

              if(!this.dados.dataFimFixacao) {
                campos.push("Data Fim Fixação");
                this.campoInvalido.dataFimFixacao = true;
              }
              else {
                this.campoInvalido.dataFimFixacao = false;
              }

              if(!this.dados.precoUnitario) {
                campos.push("Preço Unitário");
                this.campoInvalido.precoUnitario = true;
              }
              else {
                this.campoInvalido.precoUnitario = false;
              }

              if(!this.dados.unidadePrecoFixo) {
                campos.push("Unidade");
                this.campoInvalido.unidadePrecoFixo = true;
              }
              else {
                this.campoInvalido.unidadePrecoFixo = false;
              }

            }

            if(!this.dados.formaPagamento) {
                campos.push("Forma de pagamento");
                this.campoInvalido.formaPagamento = true;
            }
            else {
                this.campoInvalido.formaPagamento = false;
            }

            if(!this.dados.banco) {
                campos.push("Banco");
                this.campoInvalido.banco = true;
            }
            else {
                this.campoInvalido.banco = false;
            }

            if(!this.dados.agencia) {
                campos.push("Agência");
                this.campoInvalido.agencia = true;
            }
            else {
                this.campoInvalido.agencia = false;
            }

            if(!this.dados.conta) {
                campos.push("Conta");
                this.campoInvalido.conta = true;
            }
            else {
                this.campoInvalido.conta = false;
            }

            if(!this.dados.tipoConta) {
                campos.push("Tipo de conta");
                this.campoInvalido.tipoConta = true;
            }
            else {
                this.campoInvalido.tipoConta = false;
            }

            if(!this.dados.cidadePagamento) {
                campos.push("Cidade");
                this.campoInvalido.cidade = true;
            }
            else {
                this.campoInvalido.cidade = false;
            }

            if(!this.dados.cnpjVendedor) {
                campos.push("CNPJ do vendedor");
                this.campoInvalido.cnpjVendedor = true;
            }
            else {
                this.campoInvalido.cnpjVendedor = false;
            }

            if(this.dados.tipoFormaPagamento=="NO_DIA" && !this.dados.noDia) {
              campos.push("Data de Vencimento No Dia");
              this.campoInvalido.noDia = true;
            }
            else {
              this.campoInvalido.noDia = false;
            }

            if(this.dados.tipoFormaPagamento=="PARCELAS" && this.valorParcelas()==0) {
              campos.push("Parcelas com valor inválido");
              this.campoInvalido.parcelas = true;
            }
            else {
              this.campoInvalido.parcelas = false;
            }

            if(campos.length > 0) {
                let mensagem = `Por favor, preencher o campo: ${ campos[0] }.`;

                if(campos.length > 1) {
                    mensagem = "Por favor, preencher os campos: ";
                    mensagem += campos.join(", ");

                    const indice = mensagem.lastIndexOf(", ");
                    mensagem = `${ mensagem.substring(0, indice) } e ${ mensagem.substring(indice + 1, mensagem.length) }.`;
                }

                this.$toast.add({
                    severity: 'warn',
                    summary: 'Não foi possível salvar o contrato.',
                    detail: mensagem, 
                    life: 15000
                });
            }

            return campos.length === 0;
        },

        valorParcelas(){
          let ret = 0;
          for(let parc of this.dados.parcelas){
            ret = ret + parc.valorTotal;
          }
          return ret;
        },

        montarJson() {
            const obj = {};
            obj["id"] = this.clonar == true ? null : this.id;
            obj["bloqueioPedidos"] = !!this.dados.bloqueioPedidos?true:false;
            obj["corretoraCodigoCliente"] = this.dados.corretora.codigoCliente;
            obj["contratoCorretora"] = this.dados.contratoCorretora;
            obj["dataControtato"] = Formatacao.formatDateCustom(this.dados.dataContrato, "YYYY-MM-DD");
            obj["contratoJuridico"] = this.dados.contratoJuridico;
            obj["inicioEmbarque"] = Formatacao.formatDateCustom(this.dados.inicioEmbarque, "YYYY-MM-DD");
            obj["fimEmbarque"] = Formatacao.formatDateCustom(this.dados.fimEmbarque, "YYYY-MM-DD");
            obj["produtoId"] = this.dados.tipoProduto.id;
            obj["produtoCodItem"] = this.dados.tipoProduto.idUnico;
            obj["produtoDescItem"] = this.dados.tipoProduto.nome;
            obj["grupoProdutoId"] = this.dados.grupoProduto.id;
            obj["grupoProdutoCodigo"] = this.dados.grupoProduto.fmCodigo;
            obj["grupoProdutoDescricao"] = this.dados.grupoProduto.descricao;
            obj["quantidade"] = this.dados.quantidade;
            obj["unidade"] = this.dados.unidade.codigo;
            obj["safraId"] = this.dados.safra.id;
            obj["safra"] = this.dados.safra.safraComposta;
            obj["tipoTributacao"] = this.dados.tipoTributacao;
            obj["obeservacao"] = this.dados.observacaoMercadoria;
            
            obj["limiteUmidade"] = this.dados.limiteUmidade;
            obj["possuiUmidade"] = this.dados.possuiUmidade;
            obj["limiteImpureza"] = this.dados.limiteImpureza;
            obj["possuiImpureza"] = this.dados.possuiImpureza;
            obj["limiteGraoAvariado"] = this.dados.limiteGraoAvariado;
            obj["possuiChuvadoAvariado"] = this.dados.possuiChuvadoAvariado;
            obj["limiteTbm"] = this.dados.limiteTbm;
            obj["possuiTbm"] = this.dados.possuiTbm;
            obj["limitePh"] = this.dados.limitePh;
            obj["possuiPh"] = this.dados.possuiPh;
            obj["limiteBandinha"] = this.dados.limiteBandinha;
            obj["possuiBandinha"] = this.dados.possuiBandinha;
            obj["outrasClassificacoes"] = this.dados.outrasClassificacoes;
            obj["valorSeguro"] = this.dados.valorSeguro;
            
            const vendedor = {}
            vendedor["codigo"] = this.dados.vendedor.codProdutor;
            vendedor["nome"] = this.dados.vendedor.nome;
            vendedor["cnpj"] = this.dados.vendedor.cpfCnpj;
            vendedor["inscricaoEstadual"] = this.dados.vendedor.rgIe;
            vendedor["endereco"] = this.dados.vendedor.endereco;
            vendedor["bairro"] = this.dados.vendedor.bairro;
            vendedor["cidade"] = this.dados.vendedor.cidade;
            vendedor["unidadeFederativa"] = this.dados.vendedor.estado;
            vendedor["cep"] = this.dados.vendedor.cep;
            vendedor["representante"] = this.dados.vendedor.representante;

            const comprador = {}
            comprador["codigo"] = this.dados.comprador.codProdutor;
            comprador["nome"] = this.dados.comprador.nome;
            comprador["cnpj"] = this.dados.comprador.cpfCnpj;
            comprador["inscricaoEstadual"] = this.dados.comprador.rgIe;
            comprador["endereco"] = this.dados.comprador.endereco;
            comprador["bairro"] = this.dados.comprador.bairro;
            comprador["cidade"] = this.dados.comprador.cidade;
            comprador["unidadeFederativa"] = this.dados.comprador.estado;
            comprador["cep"] = this.dados.comprador.cep;
            comprador["representante"] = this.dados.comprador.representante;
            comprador["grupoCliente"] = this.dados.comprador.grupoCliente;

            obj["vendedor"] = vendedor;
            obj["comprador"] = comprador;
            obj["modalidadeFrete"] = this.dados.modalidadeFrete;

            obj["destinoMercadoria"] = this.dados.destinoMercadoria.codigo;
            obj["codNaturezaOperacao"] = this.dados.naturezaOperacao.codigo;
            obj["descNaturezaOperacao"] = this.dados.naturezaOperacao.descricao;

            obj["destino"] = this.dados.destino;
            obj["freteOrcado"] = this.dados.freteOrcado;
            obj["codEstabOrigemEntrega"] = this.dados.modalidadeFrete === "CIF" ? this.dados.localOrigemEntrega.codigo : null;

            obj["listaEntregaRetirada"] = this.dados.listaEntregaRetirada.map(item => {
                const entrada = Object.assign({}, item);
                entrada["dataInicio"] = Formatacao.formatDateCustom(item.dataInicio, "YYYY-MM-DD");
                entrada["dataFim"] = Formatacao.formatDateCustom(item.dataFim, "YYYY-MM-DD");
                return entrada;
            });

            obj["modalidadePrecificacao"] = this.dados.modalidadePrecificacao;

            if(this.dados.modalidadePrecificacao === "FIXO" || this.dados.modalidadePrecificacao === "FIXO_DOLAR") {
                const modalidadePrecoFixo = {};
                modalidadePrecoFixo["precoUnitario"] = this.dados.precoUnitario;
                modalidadePrecoFixo["unidade"] = this.dados.unidadePrecoFixo.codigo;
                modalidadePrecoFixo["fatorConversao"] = this.dados.fatorConversao;
                obj["modalidadePrecoFixo"] = modalidadePrecoFixo;
            }
            if(this.dados.modalidadePrecificacao === "A_FIXAR" || this.dados.modalidadePrecificacao === "FIXO_DOLAR") {
                const modalidadePrecoAFixar = {};
                modalidadePrecoAFixar["regras"] = this.dados.regras;
                modalidadePrecoAFixar["tipoFixacao"] = this.dados.tipoFixacao;
                modalidadePrecoAFixar["dolarNegociado"] = this.valorDolar;
                modalidadePrecoAFixar["composicaoValor"] = this.dados.composicaoValor;
                modalidadePrecoAFixar["detalhamentoRegra"] = this.dados.detalhamentoRegra;

                modalidadePrecoAFixar["valorFaturamentoSimbolico"] = this.dados.modalidadePrecificacao === "A_FIXAR" ?
                    this.dados.valorFaturamentoSimbolico :
                    this.dados.precoUnitario * this.valorDolar;
                modalidadePrecoAFixar["dataFimFixacao"] = Formatacao.convertStringToDate(this.dados.dataFimFixacao, "YYYY-MM-DD");

                obj["modalidadePrecoAFixar"] = modalidadePrecoAFixar;
            }

            obj["comAdiantamento"] = this.dados.comAdiantamento === "SIM";
            if(this.dados.comAdiantamento === "SIM") {
                obj["adiantamentos"] = this.dados.adiantamentos.map(item => {
                    const adiantamento = Object.assign({}, item);
                    adiantamento["data"] = undefined;
                    adiantamento["dataAdiantamento"] = Formatacao.formatDateCustom(item.data, "YYYY-MM-DD");
                    adiantamento["unidade"] = item.unidade.codigo;
                    return adiantamento;
                });
            }

            const formaPagamentoContrato = {};
            formaPagamentoContrato["tipoFormaPagamento"] = this.dados.tipoFormaPagamento;

            if(this.dados.tipoFormaPagamento=="PARCELAS"){
                formaPagamentoContrato["noDia"] = null;
                formaPagamentoContrato["numeroDias"] = null;
            } else if (this.dados.tipoFormaPagamento=="NO_DIA"){
                formaPagamentoContrato["noDia"] = this.dados.noDia;
                formaPagamentoContrato["numeroDias"] = null;
                formaPagamentoContrato["condicaoPagamento"] = null;
            } else {
                formaPagamentoContrato["noDia"] = null;
                formaPagamentoContrato["numeroDias"] = null;
                formaPagamentoContrato["condicaoPagamento"] = this.dados.condicaoPagamento;
                formaPagamentoContrato["parcelas"] = {};
                this.dados.parcelas = [];
            }

            if(this.dados.parcelas.length > 1 && this.dados.tipoFormaPagamento=="PARCELAS"){
                formaPagamentoContrato["parcelas"] = this.dados.parcelas.map(item => {
                        const parc = Object.assign({}, item);
                        parc["id"] = parc.id;
                        parc["contratoCompraVendaId"] = this.id;
                        parc["dataVencimento"] = parc.data;
                        parc["percentual"] = parc.percentual;
                        parc["valorTotal"] = parc.valorTotal;
                        return parc;
                    });
                formaPagamentoContrato["parcelas"].pop();
            } else if (this.dados.parcelas.length > 0 && this.dados.tipoFormaPagamento=="NO_DIA") {
              formaPagamentoContrato["parcelas"] = this.dados.parcelas.map(item => {
                const parc = Object.assign({}, item);
                parc["id"] = parc.id;
                parc["contratoCompraVendaId"] = this.id;
                parc["dataVencimento"] = parc.data;
                parc["percentual"] = parc.percentual;
                parc["valorTotal"] = parc.valorTotal;
                return parc;
              });
            } else {
                formaPagamentoContrato["parcelas"] = this.dados.parcelas;
            }

            obj["formaPagamento"] = formaPagamentoContrato;

            const condicaoPagamento = {};
            condicaoPagamento["formaPagamento"] = this.dados.formaPagamento.codigo;
            condicaoPagamento["bancoId"] = 1;
            condicaoPagamento["banco"] = this.dados.banco.banco;
            condicaoPagamento["agencia"] = this.dados.agencia;
            condicaoPagamento["conta"] = this.dados.conta;
            condicaoPagamento["tipoConta"] = this.dados.tipoConta;
            condicaoPagamento["cidadePagamento"] = this.dados.cidadePagamento;
            condicaoPagamento["cnpjVendedor"] = this.dados.cnpjVendedor;
            obj["informacaoDePagamento"] = condicaoPagamento;
            obj["outrosTermos"] = this.dados.outrosTermos;

            return obj;
        },

        salvar() {
            if(!this.validarCampos()) return;
            const dados = this.montarJson();
            ContratoCompraVendaService.salvar(dados)
                .then(({ data }) => {
                    if(!!data) {
                        const detalhe = 'O contrato código ' + data.id +' foi salvo com sucesso.';
                        StorageService.setMensagemToast("CONTRATO-COMPRA-VENDA", 'success', 'Contrato salvo', detalhe, true);
                        this.voltar();
                    }
                })
                .catch(error => {
                    const toast = {
                        severity: 'error',
                        summary: 'Não foi possível salvar o contrato.',
                        detail: "Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!", 
                        life: 7500
                    }

                    if(!!error && !!error.response && !!error.response.data) {
                        const { message, exception } = error.response.data;

                        if(exception === "Corretora não encontrada!") {
                            toast["detail"] = exception;
                        }
                        else {
                            toast["detail"] = message;
                        }
                    }

                    this.$toast.add(toast);
                });
        },

        pesquisarCorretora(filtros) {
            CorretoraService.buscarComPaginacao(filtros)
                .then(({ data }) => {
                    if(data) {
                        this.corretoraConsulta = data;
                    }
                })
                .catch(error => {
                    this.corretoraConsulta = {}

                    const toast = {
                        severity: 'error',
                        summary: 'Falha ao carregar as corretoras.',
                        detail: "Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!", 
                        life: 7500
                    }

                    if(!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;
                        toast["detail"] = message;
                    }

                    this.$toast.add(toast);
                });
        },

        selecionarCorretora(codigo) {
            if(codigo == undefined || codigo == null || codigo.trim() == "") {
                this.dados.corretora = {};
                return;
            }

            CorretoraService.buscarPorCodigoCliente(codigo)
                .then(({ data }) => {
                    this.dados.corretora = data;
                })
                .catch(error => {
                    this.dados.corretora = {};

                    const toast = {
                        severity: 'error',
                        summary: `Falha ao buscar corretora.`,
                        detail: "Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!", 
                        life: 7500
                    }

                    if(!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;

                        if(!!message) {
                            toast["detail"] = message;
                        }
                    }

                    this.$toast.add(toast);
                });
        },

        preencherForm(data) {
            const dados = {};
            dados["id"] = this.clonar == true ? null : data.id;
            dados["contratoInternoId"] = this.clonar == true ? null : data.contratoInternoId;
            dados["bloqueioPedidos"] = data.bloqueioPedidos;

            const corretora = {};
            corretora["nome"] = data.corretora.nome;
            corretora["codigoCliente"] = data.corretora.codigoCliente;
            corretora["cpfCnpj"] = data.corretora.cpfCnpj;
            corretora["endereco"] = data.corretora.endereco;
            corretora["bairro"] = data.corretora.bairro;
            corretora["cidade"] = data.corretora.cidade;
            corretora["estado"] = data.corretora.estado;
            corretora["email"] = data.corretora.email;
            corretora["contato"] = data.corretora.contato;
            corretora["telefone"] = data.corretora.telefone;
            corretora["celular"] = data.corretora.celular;

            dados["corretora"] = corretora;
            dados["contratoCorretora"] = data.contratoCorretora;
            dados["dataContrato"] = Formatacao.convertStringToDate(data.dataControtato, "YYYY-MM-DD");
            dados["contratoJuridico"] = data.contratoJuridico;

            dados["destino"] = data.destino;
            if(data.destino) {
                data.destino["label"] = `${ data.destino["nome"] } - ${ data.destino["uf"] }`;
            }
            
            dados["inicioEmbarque"] = data.inicioEmbarque != null ? Formatacao.convertStringToDate(data.inicioEmbarque, "YYYY-MM-DD") : null;
            dados["fimEmbarque"] = data.fimEmbarque != null ? Formatacao.convertStringToDate(data.fimEmbarque, "YYYY-MM-DD") : null;
            dados["freteOrcado"] = data.freteOrcado;
            dados["codEstabOrigemEntrega"] = data.codEstabOrigemEntrega;

            if(!!data.codEstabOrigemEntrega){
                dados["localOrigemEntrega"] = this.regionais.find(item => {
                    return item.codigo === data.codEstabOrigemEntrega;
                });
            }

            const tipoProduto = {};
            tipoProduto["idUnico"] = data.produtoCodItem;
            tipoProduto["nome"] = data.produtoDescItem;
            tipoProduto["id"] = data.produtoId;
            dados["tipoProduto"] = tipoProduto;

            const grupoProduto = {};
            grupoProduto["id"] = data.grupoProdutoId;
            grupoProduto["fmCodigo"] = data.grupoProdutoCodigo;
            grupoProduto["descricao"] = data.grupoProdutoDescricao;
            grupoProduto["codigoDescricao"] = data.grupoProdutoCodigo + " - " + data.grupoProdutoDescricao;

            if(!!data.grupoProdutoId && !!data.grupoProdutoCodigo) {
                dados["grupoProduto"] = grupoProduto;
            }
            
            dados["quantidade"] = data.quantidade;
            dados["unidade"] = UnidadeMedidaService.buscarPorCodigo(data.unidade);

            const safra = {};
            safra["safraComposta"] = data.safra;
            safra["id"] = data.safraId;

            dados["safra"] = safra;
            dados["tipoTributacao"] = data.tipoTributacao;
            dados["observacaoMercadoria"] = data.obeservacao;
            
            dados["limiteUmidade"] = data.limiteUmidade;
            dados["possuiUmidade"] = data.possuiUmidade;
            dados["limiteImpureza"] = data.limiteImpureza;
            dados["possuiImpureza"] = data.possuiImpureza;
            dados["limiteGraoAvariado"] = data.limiteGraoAvariado;
            dados["possuiChuvadoAvariado"] = data.possuiChuvadoAvariado;
            dados["limiteTbm"] = data.limiteTbm;
            dados["possuiTbm"] = data.possuiTbm;
            dados["limitePh"] = data.limitePh;
            dados["possuiPh"] = data.possuiPh;
            dados["limiteBandinha"] = data.limiteBandinha;
            dados["possuiBandinha"] = data.possuiBandinha;
            dados["outrasClassificacoes"] = data.outrasClassificacoes;
            dados["valorSeguro"] = data.valorSeguro;
            
            const vendedor = {};
            vendedor["codProdutor"] = data.vendedor.codigo;
            vendedor["nome"] = data.vendedor.nome;
            vendedor["cpfCnpj"] = data.vendedor.cnpj;
            vendedor["rgIe"] = data.vendedor.inscricaoEstadual;
            vendedor["endereco"] = data.vendedor.endereco;
            vendedor["numeroEndereco"] = data.vendedor.numeroEndereco;
            vendedor["bairro"] = data.vendedor.bairro;
            vendedor["cidade"] = data.vendedor.cidade;
            vendedor["estado"] = data.vendedor.unidadeFederativa;
            vendedor["cep"] = data.vendedor.cep;
            vendedor["representante"] = data.vendedor.representante;
           
            dados["vendedor"] = vendedor;

            const comprador = {};
            comprador["codProdutor"] = data.comprador.codigo;
            comprador["nome"] = data.comprador.nome;
            comprador["cpfCnpj"] = data.comprador.cnpj;
            comprador["rgIe"] = data.comprador.inscricaoEstadual;
            comprador["endereco"] = data.comprador.endereco;
            comprador["numeroEndereco"] = data.comprador.numeroEndereco;
            comprador["bairro"] = data.comprador.bairro;
            comprador["cidade"] = data.comprador.cidade;
            comprador["estado"] = data.comprador.unidadeFederativa;
            comprador["cep"] = data.comprador.cep;
            comprador["representante"] = data.comprador.representante;
            comprador["grupoCliente"] = data.comprador.grupoCliente;

            dados["comprador"] = comprador;

            dados["destinoMercadoria"] = data.destinoMercadoria;

            const naturezaOperacao = {};
            naturezaOperacao["codigo"] = data.codNaturezaOperacao;
            naturezaOperacao["descricao"] = data.descNaturezaOperacao;
            dados["naturezaOperacao"] = naturezaOperacao;

            /* ABA ENTREGA/RETIRADA */
            dados["modalidadeFrete"] = data.modalidadeFrete;
            dados["listaEntregaRetirada"] = data.listaEntregaRetirada.map(item => {
                item["id"] = this.clonar == true ? null : item.id;
                return item;
            });

            /* ABA PRECIFICAÇÃO */
            dados["modalidadePrecificacao"] = data.modalidadePrecificacao;
            if(dados.modalidadePrecificacao === "FIXO" || dados.modalidadePrecificacao === "FIXO_DOLAR") {
                dados["precoUnitario"] = data.modalidadePrecoFixo.precoUnitario;
                dados["unidadePrecoFixo"] = UnidadeMedidaService.buscarPorCodigo(data.modalidadePrecoFixo.unidade);
                dados["fatorConversao"] = data.modalidadePrecoFixo.fatorConversao;
            }
            if(dados.modalidadePrecificacao === "A_FIXAR" || dados.modalidadePrecificacao === "FIXO_DOLAR") {
                dados["regras"] = data.modalidadePrecoAFixar.regras.map(regra => {
                    regra["id"] = this.clonar == true ? null : regra.id;
                    regra["dataBase"] = Formatacao.convertStringToDate(regra.dataBase, "YYYY-MM-DD");
                    regra["fim"] = Formatacao.convertStringToDate(regra.fim, "YYYY-MM-DD");
                    regra["desativar"] = !regra.alteraValorPadraoCCV;
                    return regra;
                })
                dados["tipoFixacao"] = data.modalidadePrecoAFixar.tipoFixacao;
                dados["dolarNegociado"] = data.modalidadePrecoAFixar.dolarNegociado;
                this.valorDolar = data.modalidadePrecoAFixar.dolarNegociado;

                dados["composicaoValor"] = data.modalidadePrecoAFixar.composicaoValor;
                dados["detalhamentoRegra"] = data.modalidadePrecoAFixar.detalhamentoRegra;
                dados["valorFaturamentoSimbolico"] = data.modalidadePrecoAFixar.valorFaturamentoSimbolico;
                dados["dataFimFixacao"] = Formatacao.convertStringToDate(data.modalidadePrecoAFixar.dataFimFixacao, "YYYY-MM-DD");
            }
            dados["precoUnitarioKg"] = data.getPrecoUnitarioContrato;
            dados["comAdiantamento"] = data.comAdiantamento ? "SIM" : "NAO";
            if(dados.comAdiantamento === "SIM") {
                dados["adiantamentos"] = data.adiantamentos.map(item => {
                    const adiantamento = Object.assign({}, item);
                    adiantamento["id"] = this.clonar == true ? null : adiantamento.id;
                    adiantamento["data"] = Formatacao.convertStringToDate(item.dataAdiantamento, "YYYY-MM-DD");
                    adiantamento["dataAdiantamento"] = undefined;
                    adiantamento["unidade"] = UnidadeMedidaService.buscarPorCodigo(item.unidade);
                    return adiantamento;
                });
            }
            
            dados["tipoFormaPagamento"] = data.formaPagamento.tipoFormaPagamento;
            dados["noDia"] = Formatacao.convertStringToDate(data.formaPagamento.noDia, "YYYY-MM-DD");
            dados["numeroDias"] = data.formaPagamento.numeroDias;

            if(!!data.formaPagamento.condicaoPagamento){
                dados["condicaoPagamento"] = data.formaPagamento.condicaoPagamento;
                dados["descricao"] = data.formaPagamento.condicaoPagamento.descricao;
                dados["numeroParcelas"] = data.formaPagamento.condicaoPagamento.numeroParcelas;
            }

            if(!!data.formaPagamento.parcelas){
                dados["parcelas"] = data.formaPagamento.parcelas.map(parcela => {
                        parcela["id"] = this.clonar == true ? null : parcela.id;
                        parcela["contratoid"] = parcela.contratoCompraVendaId;
                        parcela["data"] = Formatacao.convertStringToDate(parcela.dataVencimento, "YYYY-MM-DD");
                        parcela["percentual"] = parcela.percentual;
                        parcela["valorTotal"] = parcela.valorTotal;
                        return parcela;
                    });
                }

            if(!!data.informacaoDePagamento) {
                const formaPagamento = {};
                formaPagamento["codigo"] = data.informacaoDePagamento.formaPagamento;
                formaPagamento["descricao"] = data.informacaoDePagamento.formaPagamento;
                dados["formaPagamento"] = formaPagamento;

                const dadosBancario = {};
                dadosBancario["banco"] = data.informacaoDePagamento.banco;
                dadosBancario["id"] = data.informacaoDePagamento.bancoId;
                dados["banco"] = dadosBancario;
                
                dados["agencia"] = data.informacaoDePagamento.agencia;
                dados["conta"] = data.informacaoDePagamento.conta;
                dados["tipoConta"] = data.informacaoDePagamento.tipoConta;
                
                if(data.informacaoDePagamento.cidadePagamento) {
                    const cidadePagamento = data.informacaoDePagamento.cidadePagamento
                    dados["cidadePagamento"] = cidadePagamento;
                    dados["cidadePagamentoDescricao"] = `${ cidadePagamento["nome"] } - ${ cidadePagamento["uf"] }`;
                }
                
                dados["cnpjVendedor"] = data.informacaoDePagamento.cnpjVendedor;
            }

            dados["outrosTermos"] = data.outrosTermos;
            this.dados = dados;
        },

        async carregarRegionais() {
            const { data } = await EstabelecimentoService.getRegionais("");
            this.regionais = data.map(item => {
                item["label"] = `${ item.codigo } - ${ item.nomeFantasia}`;
                return item;
            }); 

            this.regionais.sort((a, b) => {
                return a.codigo - b.codigo;
            });
        },

        async carregarEstabelecimentos() {
            const { data } = await EstabelecimentoService.buscarPorUsuarioAutenticadoComHierarquiaEstabelecimentoQueSejaSilo(null, 250);
            this.estabelecimentos = data.map(item => {
                item["label"] = `${ item.codigo } - ${ item.nomeFantasia}`;
                return item;
            }); 
        },

        async init(){
            this.carregarRegionais();
            this.carregarEstabelecimentos();

            if(!!this.id) {
                ContratoCompraVendaService.buscarPorId(this.id)
                    .then(({ data }) => {
                        this.dados = {};
                        this.preencherForm(data);
                    })
                    .catch(error => {
                        this.dados = {};

                        const toast = {
                            severity: 'error',
                            summary: `Falha ao buscar os dados do contrato.`,
                            detail: "Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!", 
                            life: 7500
                        }

                        if(!!error && !!error.response && !!error.response.data) {
                            const { message, exception } = error.response.data;

                            if(!!exception) {
                                toast["detail"] = exception;
                            }
                        }

                        this.$toast.add(toast);
                    });
                } else {
                this.obterValorDolar();
              }
            }
    },

    mounted(){
        this.init();
    }
}
</script>
<template>
    <Dialog v-model:visible="visivel" :style="{width: '600px', margin: '10%'}" :modal="true" @show="carregarEstabelecimentosDaRegional(true)" @hide="fechar()">
        <template #header>
            <div class="flex align-items-center mt-2">
                <img src="layout/images/order.png" alt="" style="width: 2rem;"/>
                <h4 style="margin:4px;">Entrega/Retirada</h4>
            </div> 
        </template>

        <Message v-if="mensagem.ativo" :severity="mensagem.gravidade" @close="mensagem.ativo = false">{{ mensagem.conteudo }}</Message>

        <div class="grid p-fluid formgrid p-3 ml-1 mr-1" style="border: solid 1px #cecece;">

            <div class="col-12" v-if="modalidadeFrete == 'CIF'">
                <div class="field">
                    <label>
                        <strong>Regional de Origem Preferencial</strong>
                    </label>
                    <InputText class="w-full" v-model="dados.localOrigemEntrega.label" disabled/>
                </div>
            </div>

            <div class="col-12" v-if="modalidadeFrete == 'FOB'">  
                <div class="field"> 
                    <label> <strong>Regional de Origem<span style="color:red;">&nbsp;*</span></strong> </label>
                    <AutoComplete
                        class="w-full"
                        placeholder="Informe a regional de retirada"
                        v-model="dados.regionalOrigemEntrega"
                        field="label"
                        :suggestions="regionaisFiltradas"
                        :dropdown="true"
                        @complete="buscarRegionais($event)"
                        @item-select="carregarEstabelecimentosDaRegional(false)"
                        :class="{ 'w-full p-invalid': isValidRegionalOrigem }"
                    ></AutoComplete>
                </div>         
            </div>

            <div class="col-12" v-if="modalidadeFrete == 'FOB'">  
                <div class="field"> 
                    <label> <strong>Local de Origem<span style="color:red;">&nbsp;*</span></strong> </label>
                    <AutoComplete
                        class="w-full"
                        :placeholder="placeholderLocalOrigem()"
                        v-model="dados.localOrigemEntrega"
                        field="label"
                        :disabled="!possuiEstabelecimentosDaRegional()"
                        :suggestions="estabelecimentosFiltrados"
                        :dropdown="true"
                        @complete="buscarEstabelecimentos($event)"
                        :class="{ 'w-full p-invalid': isValidOrigem }"
                    ></AutoComplete>
                </div>         
            </div>

            <div class="col-12">
                <div class="field">
                    <label>
                        <strong>Destino da Mercadoria</strong>
                    </label>
                    <InputText class="w-full" v-model="destino.label" disabled/>
                </div>
            </div>

            <div class="col-4 sm:col-4 md:col-4 lg:col-4 xl:col-4">
                <div class="field">
                    <label for="quantidade">
                        <strong>Quantidade<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <InputNumber
                        class="w-full"
                        :class="{ 'p-invalid': isValidQuantidade }"
                        v-model="dados.quantidade"
                        id="quantidade"
                        locale="pt-BR"
                        :max="9999999"
                        :minFractionDigits="2"
                        :maxFractionDigits="2"
                        @focus="limparMensagem"
                    />
                </div>
            </div>

            <div class="col-4 sm:col-4 md:col-4 lg:col-4 xl:col-4">
                <div class="field">
                    <label for="data_inicio">
                        <strong>Data início<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <Calendar
                        class="w-full"
                        :class="{ 'p-invalid': isValidDataInicio }"
                        id="data_inicio"
                        v-model="dados.dataInicio"
                        dateFormat="dd/mm/yy"
                    />
                </div>
            </div>

            <div class="col-4 sm:col-4 md:col-4 lg:col-4 xl:col-4">
                <div class="field">
                    <label for="data">
                        <strong>Data fim<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <Calendar
                        class="w-full"
                        :class="{ 'p-invalid': isValidDataFim }"
                        id="data_fim"
                        v-model="dados.dataFim"
                        dateFormat="dd/mm/yy"
                        :minDate="dados.dataInicio"
                    />
                </div>
            </div>

            <Fieldset legend="Tipo de transporte *" class="col-12 mb-3">
                <div class="flex align-items-center">
                    <div class="flex align-items-center ml-3">
                        <RadioButton inputId="trem" value="TREM" v-model="dados.tipoTransporte" :class="{ 'p-invalid': isValidTipoTransporte }"/>
                        <label for="trem" class="ml-2">Trem</label>
                    </div>
                    <div class="flex align-items-center ml-3">
                        <RadioButton inputId="caminhao" value="CAMINHAO" v-model="dados.tipoTransporte" :class="{ 'p-invalid': isValidTipoTransporte }"/>
                        <label for="caminhao" class="ml-2">Caminhão</label>
                    </div>
                </div>
            </Fieldset>

            <div class="col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12">
                <div class="field">
                    <label for="dados_adicionais">
                        <strong>Dados adicionais</strong>
                    </label>
                    <Textarea class="w-full" v-model="dados.dadosAdicionais" id="dados_adicionais"></Textarea>
                </div>
            </div>
        </div>

        <template #footer>
            <div class="flex justify-content-center">
                <Button
                    icon="pi pi-save"
                    label="Salvar"
                    class="p-button-info ml-1"
                    @click="salvar()"
                ></Button>

                <Button
                    icon="pi pi-times"
                    label="Cancelar"
                    class="p-button-danger"
                    @click="fechar()"
                ></Button>
            </div>
        </template>
    </Dialog>
</template>

<script>
export default {
    name: "ModalEntregaRetirada",
    emits: ["fechar", "salvar"],
    props: {
        visivel: {
            type: Boolean,
            required: true
        },
        dados: {
            type: Object,
            required: true
        },
        destino: {
            type: Object,
            required: true
        },
        estabelecimentos: {
            type: Object,
            required: true
        },
        regionais: {
            type: Object,
            required: true
        },
        modalidadeFrete: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            regionaisFiltradas: [],
            estabelecimentosFiltrados: [],
            estabelecimentosDaRegional: [],

            mensagem: { ativo: false, gravidade: 'info', conteudo: '' },

            isValidQuantidade: false,
            isValidDataInicio: false,
            isValidDataFim: false,
            isValidOrigem: false,
            isValidRegionalOrigem: false,
            isValidTipoTransporte: false
        }
    },
    updated() {
    },
    methods: {
        isCamposObrigatoriosPreenchidos() {
            let isPreenchido = true;
            
            if(!this.dados.quantidade) {
                this.isValidQuantidade = true;
                isPreenchido = false;
            }

            if(!this.dados.dataInicio) {
                this.isValidDataInicio = true;
                isPreenchido = false;
            }

            if(!this.dados.dataFim) {
                this.isValidDataFim = true;
                isPreenchido = false;
            }
            
            if(this.modalidadeFrete == 'FOB' && !this.dados.regionalOrigemEntrega) {
                this.isValidRegionalOrigem = true;
                isPreenchido = false;
            }
            
            if(this.modalidadeFrete == 'FOB' && !this.dados.localOrigemEntrega) {
                this.isValidOrigem = true;
                isPreenchido = false;
            }

            if(!this.dados.tipoTransporte) {
                this.isValidTipoTransporte = true;
                isPreenchido = false;
            }

            if(!isPreenchido) {
                this.mensagem = {
                    ativo: true,
                    gravidade: 'warn',
                    conteudo: 'Por favor, preencher os campos obrigatórios!'
                }
            }

            return isPreenchido;
        },

        limpar() {
            this.isValidQuantidade = false;
            this.isValidDataInicio = false;
            this.isValidDataFim = false;
            this.isValidOrigem = false;
            this.isValidRegionalOrigem = false;
            this.isValidTipoTransporte = false;
        },

        limparMensagem() {
            this.mensagem = { ativo: false };
        },

        salvar() {
            if(!this.isCamposObrigatoriosPreenchidos()) return;

            const entregaRetirada = Object.assign({}, this.dados);

            entregaRetirada['codRegionalOrigemEntrega'] = this.dados.regionalOrigemEntrega?.codigo;
            entregaRetirada['nomeRegionalOrigemEntrega'] = this.dados.regionalOrigemEntrega?.nomeFantasia;

            entregaRetirada['codEstabOrigemEntrega'] = this.dados.localOrigemEntrega?.codigo;
            entregaRetirada['nomeEstabOrigemEntrega'] = this.dados.localOrigemEntrega?.nomeFantasia;
            
            this.$emit("salvar", entregaRetirada);
            
            if(entregaRetirada.indice !== undefined && entregaRetirada.indice !== null) {
                this.fechar();
            }
            else {
                this.limparMensagem();
                this.limpar();
                this.mensagem = {
                    ativo: true,
                    gravidade: 'success',
                    conteudo: 'Dados adicionados com sucesso!'
                }
                this.fechar();
            }
        },

        fechar() {
            this.$emit("fechar");
            this.limpar();
            this.limparMensagem();
        },

        buscarRegionais(event) {
            setTimeout(() => {
                if(!event.query.trim().length) {
                    this.regionaisFiltradas = [...this.regionais];
                }
                else {
                    this.regionaisFiltradas = this.regionais.filter((item) => {
                        return item.label.toLowerCase().startsWith(event.query.toLowerCase());
                    });
                }
            }, 250);
        },

        carregarEstabelecimentosDaRegional(showModal = false){            
            if(!this.dados.regionalOrigemEntrega) {
                return;
            }
            this.estabelecimentosDaRegional = this.estabelecimentos.filter((item) => {
                return item.codigoRegional === this.dados.regionalOrigemEntrega.codigo;
            });
            this.estabelecimentosFiltrados = [...this.estabelecimentosDaRegional];
            if(!showModal) this.dados.localOrigemEntrega = null;
        },

        possuiEstabelecimentosDaRegional(){
            if(!this.dados.regionalOrigemEntrega) {
                this.dados.localOrigemEntrega = null;
                return false;
            }
            return this.estabelecimentosDaRegional.length > 0;
        },

        placeholderLocalOrigem(){
            if(!this.dados.regionalOrigemEntrega) return "";
            if(this.estabelecimentosDaRegional.length == 0) return "Nenhum local de origem encontrado.";
            return "Informe o local de retirada da mercadoria";
        },

        buscarEstabelecimentos(event) {
            setTimeout(() => {
                if(!event.query.trim().length) {
                    this.estabelecimentosFiltrados = [...this.estabelecimentosDaRegional];
                }
                else {
                    this.estabelecimentosFiltrados = this.estabelecimentosDaRegional.filter((item) => {
                        return item.label.toLowerCase().startsWith(event.query.toLowerCase());
                    });
                }
            }, 250);
        }
    }
}
</script>